const sessionsTranslations = {
  en: {
    accounts: "Accounts",
    pid: "PID",
    room: "Room",
    aid: "AID",
    cid: "CID",
    type: "Type",
    sessionTime: "Session Time",
    fuelBilled: "Fuel Billed",
    hands: "Hands",
    level: "Level",
    win: "Win",
    rake: "Rake",
    insur: "Insur",
    errors: "Errs",
    server: "Server",
    empty: "Empty",
    active: "Active",
    downloadCSV: "Download CSV",
    apply: "Apply",
    specify_date_from: "Specify Date from",
    specify_date_to: "Specify Date to",
  },
  zh: {
    accounts: "账号",
    pid: "账号ID",
    room: "平台",
    aid: "联盟号",
    cid: "俱乐部号",
    type: "类型",
    sessionTime: "牌局时间",
    fuelBilled: "燃料消耗",
    hands: "手数",
    level: "级别",
    win: "战绩",
    rake: "抽水",
    insur: "保险",
    errors: "误差",
    server: "服务器",
    empty: "空",
    active: "运行中",
    downloadCSV: "下载CSV",
    apply: "申请",
    // fixme
    specify_date_from: "指定起始日期",
    specify_date_to: "指定结束日期",
  },
  ja: {
    accounts: "アカウント",
    pid: "プレイヤーID",
    room: "ルーム",
    aid: "リーグID",
    cid: "クラブID",
    type: "タイプ",
    sessionTime: "セッション時間",
    fuelBilled: "請求燃料",
    hands: "ハンド数",
    level: "レベル",
    win: "ウィンレート",
    rake: "レーキ",
    insur: "インシュランス",
    errors: "エラー",
    server: "サーバ",
    empty: "空",
    active: "アクティブ",
    downloadCSV: "CSVをダウンロードする",
    apply: "適用",
    specify_date_from: "から日付を指定する",
    specify_date_to: "に日付を指定する",
  },
};

export default sessionsTranslations;
