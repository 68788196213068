import { useMemo } from "react";

import { FilterSelect } from "@/shared/ui/filter-select";
import { useSessionPageStore } from "@/store/session-page-store";

export function SessionsRoomTypeFilter({ options, ...props }) {
  const { searchParams, setSearchParam, setSearchParams } = useSessionPageStore();
  const sessionPageStore = useSessionPageStore();

  const roomTypesOptions = useMemo(() => {
    if (
      !sessionPageStore.allowedRooms ||
      !sessionPageStore.allowedRooms.roomTypes
    ) {
      // fixme extract
      return [{ value: null, label: "All" }];
    }
    return [{ value: null, label: "All" }].concat(
      sessionPageStore.allowedRooms.roomTypes
    );
  }, [sessionPageStore.allowedRooms]);

  return (
    <FilterSelect
      placeholder="Room type"
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      options={options || roomTypesOptions}
      defaultValue={roomTypesOptions?.[0]}
      {...props}
    />
  );
}
