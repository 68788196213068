import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, NavLinkProps } from "react-router-dom";

import { useCurrentPartnerInfo } from "@/entities/partner/model/use-current-partner-info";

export function HeaderPartnersLink({ ...props }: Omit<NavLinkProps, "to">) {
  const { t } = useTranslation("header");
  const currentUserFull = useCurrentPartnerInfo();

  const isOwnSub = useMemo(
    () => currentUserFull?.name === "000000000",
    [currentUserFull?.name]
  );

  return (
    <NavLink
      id="partnersLink"
      to="/partners"
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
      {...props}
    >
      {isOwnSub ? t("retail") : t("partners")}
    </NavLink>
  );
}
