import { useCurrentPartnerInfo } from "./use-current-partner-info";
import { usePartnerRooms } from "./use-partner-rooms";

export function useCurrentPartnerRooms() {
  const currentPartner = useCurrentPartnerInfo();
  return usePartnerRooms(
    {
      partId: currentPartner?.partId,
    },
    {
      enabled: !!currentPartner?.partId,
    }
  );
}
