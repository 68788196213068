import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAccountsStore } from "@/entities/account/model/accounts-store";
import { useUserRole } from "@/entities/user/use-user-role";

export function AccountsTableHead({ ...props }) {
  const { t } = useTranslation("accounts");
  const { selectAllAccounts, fetchAccounts, accounts, selectedAccounts } =
    useAccountsStore();

  const selectAllRows = useCallback(() => {
    selectAllAccounts();
  }, [selectAllAccounts]);

  const isSelectAllChecked = useMemo(() => {
    return (
      selectedAccounts?.length && accounts?.length === selectedAccounts?.length
    );
  }, [accounts?.length, selectedAccounts?.length]);

  const role = useUserRole();
  const isAdminManager = role === "ADMIN" || role === "MANAGER";

  return (
    <div className="table-th" {...props}>
      <div>#</div>
      <div>
        {/*fixme extract*/}
        <input
          type="checkbox"
          className="select-item"
          onChange={selectAllRows}
          checked={isSelectAllChecked}
        />
      </div>
      <div>{t("accs")}</div>
      <div className="editable-note-th">{t("notes")}</div>
      <div>{t("room")}</div>
      <div>{t("state")}</div>
      <div>{t("type")}</div>
      <div>{t("mode")}</div>
      <div>{t("level")}</div>
      <div>{t("status")}</div>
      <div>{t("hands")}</div>
      <div>{t("win")}</div>
      <div>{t("rake")}</div>
      <div>{t("insur")}</div>
      <div>{t("errs")}</div>
      <div>{t("version")}</div>
      <div>{t("lastGame")}</div>
      {isAdminManager && <div>{t("deviceInfo")}</div>}
    </div>
  );
}
