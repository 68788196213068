import { css } from "@emotion/react";
import React, { useCallback, useEffect, useState } from "react";
import Confetti from "react-confetti";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useEffectOnce } from "react-use";

import { api } from "@/shared/constants";
import { ReactComponent as SvgClose } from "@/shared/images/close.svg";
import { useDepositStore } from "@/store/DepositStore";
import { useHeaderStore } from "@/store/HeaderStore";

const ConfettiContainer = ({ numberOfPieces, setShowConfetti }) => {
  useEffect(() => {
    const stopConfetti = setTimeout(() => {
      setShowConfetti(false);
    }, 4000);

    return () => clearTimeout(stopConfetti);
  }, [setShowConfetti]);

  return ReactDOM.createPortal(
    <div className="confetti-container">
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={numberOfPieces}
        recycle={false}
      />
    </div>,
    document.querySelector(".modal-overlay")
  );
};

const DepositModal = ({ closeModal }) => {
  const { t: apiT } = useTranslation("api");
  const { t } = useTranslation("header");
  const [entered, setEntered] = useState(10);
  const [actCalc, setActCalc] = useState(null);
  const [nextCalc, setNextCalc] = useState();
  const [paymentContent, setPaymentContent] = useState("initial");
  const [showConfetti, setShowConfetti] = useState(false);

  const [confettiParticles, setConfettiParticles] = useState(500);

  const [confirmations, setConfirmations] = useState(null);

  const [isFetching, setIsFetching] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);

  const headerStore = useHeaderStore();
  const depositStore = useDepositStore();
  const invoice = depositStore.invoice;

  useEffectOnce(() => {
    depositStore.initialRequest();
  });

  const sortedCalculation = depositStore.gifts
    .slice()
    .sort((a, b) => a.amount - b.amount);

  useEffect(() => {
    let actCalcIndex = -1;
    for (let i = 0; i < sortedCalculation.length; i++) {
      if (sortedCalculation[i].amount <= Number(entered)) {
        actCalcIndex = i;
      } else {
        break;
      }
    }
    if (actCalcIndex !== -1) {
      setActCalc(sortedCalculation[actCalcIndex]);
      if (actCalcIndex < sortedCalculation.length - 1) {
        setNextCalc(sortedCalculation[actCalcIndex + 1]);
      } else {
        setNextCalc(null);
      }
    } else {
      setActCalc(null);
      setNextCalc(sortedCalculation[0]);
    }
  }, [entered, sortedCalculation]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setEntered(value);
    }
  };

  const handleAddClick = useCallback(() => {
    if (nextCalc) {
      setEntered(Number(nextCalc.amount));
    } else {
      setConfettiParticles(500);
      // setShowConfetti(true);
    }
  }, [nextCalc]);

  // fixme use query
  useEffect(() => {
    if (isFetching && invoice?.invId) {
      setTransaction(true);
      const fetchInterval = setInterval(async () => {
        try {
          const trxState = await api.manualCallbackReq({
            invId: invoice?.invId,
          });
          if (trxState?.data?.status === "SUCCESS") {
            setConfirmations(trxState?.data?.confirmationsCnt);
            if (trxState?.data?.txCompleted) {
              setTimeout(() => {
                toast.success(t("depositSuccessful"), {
                  delay: 0,
                  autoClose: 5000,
                  position: "bottom-center",
                });
                setIsFetching(false);
                setTransaction(false);
                headerStore.fetchBalance().then();
                closeModal();
              }, 100);
            }
          } else {
            setIsFetching(false);
            setTransaction(false);
            toast.error(apiT(trxState?.data?.code || "SOMETHING_WENT_WRONG"), {
              delay: 0,
              autoClose: 5000,
              position: "bottom-center",
            });
          }
        } catch (err) {
          toast.error(apiT("SOMETHING_WENT_WRONG"), {
            delay: 0,
            autoClose: 5000,
            position: "bottom-center",
          });
        }
      }, 11000);
      return () => {
        clearInterval(fetchInterval);
      };
    }
  }, [apiT, closeModal, headerStore, invoice?.invId, isFetching, t]);

  const handlePayClick = () => {
    setIsInvoiceLoading(true);
    depositStore.createInvoice(entered).then(() => {
      setPaymentContent("next_step");
      setIsInvoiceLoading(false);
    });
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(invoice.walletAddressIn)
      .then(() => {
        toast.success(t("copied"));
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to copy address.");
      });
  };

  const onCloseModal = () => {
    closeModal();
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {
    if (depositStore.gifts.length && nextCalc === null) {
      setShowConfetti(true);
    } else {
      setShowConfetti(false);
    }
  }, [depositStore.gifts.length, nextCalc]);

  return (
    <div className="modal-deposit">
      <div className="m-deposit-title">
        {t("deposit")}
        <div className="window_close" onClick={onCloseModal}>
          <SvgClose />
        </div>
      </div>
      {paymentContent === "initial" ? (
        <div className="m-deposit-inner">
          <div className="m-deposit-go">
            <div className="m-deposit-input">
              <span>{t("usdtTrc20Amount")}</span>
              <input
                type="text"
                className="st-in"
                value={entered}
                onChange={handleChange}
              />
              {actCalc && (
                <span
                  className="m-deposit-input-gift"
                  css={css`
                    &&& {
                      background: var(--accent-color);
                      border-radius: 8px;
                      color: white;
                      padding: 0.25rem;
                    }
                  `}
                >
                  gift + {actCalc.percent}%
                </span>
              )}
            </div>
            <div className="m-deposit-fuel">
              <span>{t("fuelAmount")}</span>
              <span className="fuel-amount-number">
                {actCalc
                  ? formatNumber(
                      (Number(entered) *
                        depositStore.usdtToFuelRatio *
                        (100 + actCalc.percent)) /
                        100
                    )
                  : formatNumber(entered * depositStore.usdtToFuelRatio)}
              </span>
            </div>
          </div>
          {nextCalc ? (
            <div
              className="m-deposit-add"
              onClick={handleAddClick}
              css={css`
                &&& {
                  background: var(--accent-color);
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 0.5rem;
                  height: auto;
                  line-height: normal;
                  padding: 0.5rem;
                }
              `}
            >
              <div>
                {t("add")} <span>{nextCalc.amount - Number(entered)}</span> USDT{" "}
                {t("nextGift")}
                &nbsp;
                <span>{nextCalc.percent}%</span>
              </div>
            </div>
          ) : (
            ""
          )}
          {depositStore.gifts.length && nextCalc === null ? (
            <div
              className="m-deposit-add congrats"
              onClick={handleAddClick}
              css={css`
                &&& {
                  background: var(--accent-color);
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 0.5rem;
                  height: auto;
                  line-height: normal;
                  padding: 0.5rem;
                }
              `}
            >
              <b>{t("congrats")}</b>
              <div>{t("youHaveReachedTheMaximumGift")}!</div>
            </div>
          ) : (
            ""
          )}

          {/* Здесь выводим содержимое блока в зависимости от состояния paymentContent */}

          <button
            className={`p-btn m-deposit-pay grayBg ${
              isInvoiceLoading ? "updating" : ""
            }`}
            onClick={handlePayClick}
          >
            {isInvoiceLoading ? "" : t("pay")}
          </button>
        </div>
      ) : (
        <div className="m-deposit-pay">
          <div className="deposit-to-pay">
            {t("send")}
            <span> {entered} USDT TRC20</span>
          </div>
          <div onClick={handleCopyClick} className="p-btn">
            {t("copyAddress")}
          </div>
          <div className="address">{invoice.walletAddressIn}</div>
          <div className="deposit-qr-t">{t("scanFromQr")}</div>
          <div>
            {invoice?.qrCode && (
              <img
                className="deposit-qr"
                src={`data:image/png;base64,${invoice.qrCode}`}
                alt="Decoded PNG"
              />
            )}
          </div>
          <div className="deposit-received">
            {transaction ? (
              isFetching ? (
                <div
                  css={css`
                    &&& {
                      display: flex;
                      align-items: center;
                      @keyframes blink {
                        0% {
                          opacity: 1;
                        }
                        50% {
                          opacity: 0;
                        }
                        100% {
                          opacity: 1;
                        }
                      }
                      animation: ${isFetching
                        ? "blink normal 2s infinite ease-in-out"
                        : "none"};
                    }
                  `}
                >
                  <b>{confirmations ? confirmations : 0}</b>&nbsp;
                  {t("confirmations")}
                </div>
              ) : (
                <>{t("transactionReceived")}</>
              )
            ) : (
              <>&nbsp;</>
            )}
          </div>
          <button
            onClick={() => setIsFetching(true)}
            disabled={transaction}
            className="paid-check"
            css={css`
              &&& {
                opacity: ${transaction ? 0.5 : 1};
              }
            `}
          >
            {t("iPaid")}
          </button>
        </div>
      )}
      {showConfetti && (
        <ConfettiContainer
          numberOfPieces={confettiParticles}
          setShowConfetti={setShowConfetti}
        />
      )}
    </div>
  );
};

export default DepositModal;
