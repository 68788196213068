const configTranslation = {
  en: {
    brain_type: "AI type",
    stats_profile: "AI profile",
    select_enable: "Table select",
    auto_rebuy: "Auto rebuy",
    rebuy_trigger: "Rebuy trigger",
    rebuy_amount: "Rebuy amount",
    rebuy_step: "Rebuy step",
    insur_flop_behaviour: "Insurance flop",
    insur_flop_freq: "frequency",
    insur_flop_amount: "amount",
    insur_turn_freq: "frequency",
    insur_turn_amount: "amount",
    exit_enable: "Exit rules",
    exit_min_hands: "Min hands",
    exit_min_hands_at_profit: "Min hands at profit",
    exit_win_bb: "By win BB",
    exit_lose_bb: "By lose bb",
    exit_fullstack_lose: "By full stack lose",
    exit_max_hands: "By max hands",
    exit_lifetime: "By table lifetime",
    timings_enable: "Custom timings",
    timings_bets: "Bets",
    timings_calls: "Calls",
    timings_checks: "Checks",
    timings_folds: "Folds",
    timings_raises: "Raises",
    auto_buyin_enable: "Auto buy-in",
    limit: "Limit",
    buyin_club_id: "Club ID",
    buyin_amount: "Buy-in amount",
    buyin_step: "Buy-in step",
    maxbot_amount: "Maxbot",
    show_tips_enable: "Show tips",
    tips_lifetime: "Tips lifetime",
    opponents_amount: "Opponents amount",
    table_lifetime: "Table lifetime",
    auto_room_coins_check: "Balance check",
    auto_alc_return: "Balance return after game",
    balance_operations_enable: "Balance operations",
    auto_table_selection: "Automatic table selection",
    scheduler_enable: "Game scheduler",
    timings_insur_buy: "Insurance buy",
    timings_insur_fold: "Insurance cancel",
    insur_turn_behaviour: "Insurance turn",
    exit_min_opponents: "By min opponents",
    exit_table_select: "By table select",
    scheduler_start_time: "Start time",
    scheduler_play_time: "Play time",
    auto_cancel: "Auto cancel",
    buy_manually: "Buy manually",
    buy_automatically: "Buy automatically",
    save: "Save",
    reset: "Reset",
    accountConfiguration: "Settings",
    usual: "Usual",
    vip: "VIP",
    accept: "Accept",
    // fixme data has "title" for this
    use_quickbuttons: "Use quickbuttons",
    basic: "Basic",
    pro: "Pro",
    ai_source: "AI source",
    stats_profile_ob_enable: "AI profile OB",
    stats_profile_ob: "AI profile OB",
  },
  zh: {
    brain_type: "AI类型",
    stats_profile: "AI配置",
    select_enable: "选择桌子",
    auto_rebuy: "自动补码",
    rebuy_trigger: "补码触发",
    rebuy_amount: "补码金额 ",
    rebuy_step: "补码步骤",
    insur_flop_behaviour: "翻牌保险",
    insur_flop_freq: "翻牌保险频率",
    insur_flop_amount: "翻牌保险范围",
    insur_turn_freq: "转牌保险频率",
    insur_turn_amount: "转牌保险范围",
    exit_enable: "退出规则",
    exit_min_hands: "最低手数",
    exit_min_hands_at_profit: "盈利情况下最低手数",
    exit_win_bb: "按BB赢利",
    exit_lose_bb: "按输的BB",
    exit_fullstack_lose: "按照输全额筹码",
    exit_max_hands: "按最高手数",
    exit_lifetime: "按桌子时长",
    timings_enable: "自定义时间",
    timings_bets: "下注",
    timings_calls: "跟注",
    timings_checks: "过牌",
    timings_folds: "弃牌",
    timings_raises: "加注",
    auto_buyin_enable: "自动带入",
    show_tips_enable: "显示提示",
    tips_lifetime: "提示时长",
    limit: "级别",
    buyin_club_id: "俱乐部ID",
    buyin_amount: "带入金额",
    buyin_step: "带入步骤",
    maxbot_amount: "最大机器人数量",
    opponents_amount: "对手数量",
    table_lifetime: "桌子时长",
    auto_room_coins_check: "检查余额",
    auto_alc_return: "游戏结束后返回余额",
    balance_operations_enable: "余额操作",
    auto_table_selection: "自动选局",
    scheduler_enable: "游戏调度",
    timings_insur_buy: "购买保险",
    timings_insur_fold: "取消保险",
    insur_turn_behaviour: "转牌保险",
    exit_min_opponents: "按最低对手数量",
    exit_table_select: "按选择的桌子",
    scheduler_start_time: "开始时间",
    scheduler_play_time: "游戏时间",
    auto_cancel: "自动取消",
    buy_manually: "手动购买",
    buy_automatically: "自动购买",
    save: "保存",
    reset: "重置",
    accountConfiguration: "设置",
    usual: "通常",
    vip: "VIP",
    accept: "接受",
    use_quickbuttons: "用快捷按钮",
    basic: "Basic",
    pro: "Pro",
    ai_source: "AI源",
    stats_profile_ob_enable: "无玩家机器人模式",
    stats_profile_ob: "无玩家机器人模式",
  },
  ja: {
    brain_type: "AIのタイプ",
    stats_profile: "人工知能",
    select_enable: "テーブルを選択する",
    auto_rebuy: "オートリバイ",
    rebuy_trigger: "リバイのトリガー",
    rebuy_amount: "リバイの金額",
    rebuy_step: "リバイのステップ",
    insur_flop_behaviour: "インシュランスフロップ ",
    insur_flop_freq: "回数",
    insur_flop_amount: "金額",
    insur_turn_freq: "回数",
    insur_turn_amount: "金額",
    exit_enable: "撤退基準",
    exit_min_hands: "最小ハンド数",
    exit_min_hands_at_profit: "利益の最小ハンド数",
    exit_win_bb: "勝利のBB",
    exit_lose_bb: "敗北のBB",
    exit_fullstack_lose: "フルスタックの敗北",
    exit_max_hands: "最大ハンド数",
    exit_lifetime: "テーブルの表示時間",
    timings_enable: "カスタムタイミング",
    timings_bets: "ベット",
    timings_calls: "コール",
    timings_checks: "チェック",
    timings_folds: "フォールド",
    timings_raises: "レイズ",
    auto_buyin_enable: "自動バイイン",
    limit: "リミット",
    buyin_club_id: "クラブID",
    buyin_amount: "バイインの金額",
    buyin_step: "バイインのステップ",
    maxbot_amount: "最大ボット数",
    show_tips_enable: "ヒントを表示する",
    tips_lifetime: "ヒントの表示時間",
    opponents_amount: "相手の数",
    table_lifetime: "テーブルの表示時間",
    auto_room_coins_check: "残高確認",
    auto_alc_return: "ゲームの後、残高がクラブに戻る",
    balance_operations_enable: "残高操作",
    auto_table_selection: "テーブルを自動的に選択する",
    scheduler_enable: "ゲームスケジューラ",
    timings_insur_buy: "インシュランスを購入する",
    timings_insur_fold: "インシュランスをキャンセルする",
    insur_turn_behaviour: "インシュランスをターンする",
    exit_min_opponents: "最小相手の数",
    exit_table_select: "テーブル選択",
    scheduler_start_time: "開始時間",
    scheduler_play_time: "プレイ時間",
    auto_cancel: "自動キャンセル",
    buy_manually: "手動で購入する",
    buy_automatically: "自動的に購入する",
    save: "保存",
    reset: "リセット",
    accountConfiguration: "設定",
    usual: "通常",
    vip: "VIP",
    accept: "承諾",
    use_quickbuttons: "クイックボタンを使用する",
    basic: "基本",
    pro: "プロ",
    ai_source: "AIソース",
    stats_profile_ob_enable: "人工知能のみ",
    stats_profile_ob: "人工知能のみ",
  },
};

export default configTranslation;
