import { Stack } from "@mui/material";
import { getI18n } from "react-i18next";
import { ToastOptions, toast } from "react-toastify";

export type CommonApiRespData = {
  status?: string;
  /** Какое-нибудь описание */
  code?: string;
  /** Дополнительная инфа к ошибке */
  codeArg?: string;
  /** @format int32 */
  codeNum?: number;
};

export function toastError(
  { code, codeArg }: any,
  options?: ToastOptions<any> | undefined
) {
  toast?.error(
    () => (
      <Stack alignItems="center">
        <Stack>{getI18n()?.t(`api:${code || "SOMETHING_WENT_WRONG"}`)}</Stack>
        <Stack>{!!codeArg && codeArg}</Stack>
      </Stack>
    ),
    {
      delay: 0,
      autoClose: 5000,
      position: "bottom-center",
      ...options,
    }
  );
}
