import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import {
  PartnersReqN,
  PartnersRespN,
  RetailersReqN,
  RetailersRespN,
} from "@/api/Api";
import { useCurrentPartnerInfo } from "@/entities/partner/model/use-current-partner-info";
import { api } from "@/shared/constants";

export const usePartnersRetailersQueryKey = `partners-retailers`;

export type PartnersRetailersReqN = PartnersReqN & RetailersReqN;
export type PartnersRetailersRespN = PartnersRespN & RetailersRespN;

export function usePartnersRetailers(
  query: Omit<PartnersRetailersReqN, "partId">,
  queryOptions?: Omit<
    UseQueryOptions<PartnersRetailersRespN["partners" | "retailers"]>,
    "queryKey"
  >
) {
  const currentUserFull = useCurrentPartnerInfo();

  const isOwnSub = useMemo(
    () => currentUserFull?.name === "000000000",
    [currentUserFull?.name]
  );

  return useQuery({
    queryKey: [
      usePartnersRetailersQueryKey,
      { partId: currentUserFull?.partId, ...query },
    ],
    placeholderData: (prev) => prev,
    queryFn: async () => {
      return isOwnSub
        ? api
            ?.retailersReqN({ partId: currentUserFull?.partId, ...query })
            ?.then((response) => response?.data?.retailers)
        : api
            ?.partnersReqN({ partId: currentUserFull?.partId, ...query })
            ?.then((response) => response?.data?.partners);
    },
    enabled: !!currentUserFull?.partId,
    refetchOnWindowFocus: "always",
    ...queryOptions,
  });
}
