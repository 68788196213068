import Cookies from "js-cookie";
import { create } from "zustand";

import { api, sessionStore } from "@/shared/constants";
import { getPartnersIds } from "@/shared/utils/utils";

const userData = JSON.parse(sessionStorage.getItem("userData"));

export const useSessionStore = create((set, get) => ({
  data: userData,
  currentUser: userData ? userData.partId : null,
  subPartners: null,
  role: userData ? userData.role : null,
  apkUrl: sessionStorage.getItem("apkUrl"),
  agentCode: sessionStorage.getItem("agentCode"),
  theme: Cookies.get("isDarkMode") === "1" ? "dark" : "light",
  loginLoading: false,
  setTheme: (theme) => set({ theme: theme }),
  getSubpartnersReq: async () => {
    try {
      const response = await api.getSubpartnersReq();
      const parsedData = response?.data;

      // console.log("xxxxxxxxxxxxxx length ", parsedData?.subPartners?.length);
      //
      // parsedData?.subPartners
      //   ?.sort((a, b) => (a.partnerName > b.partnerName ? 1 : -1))
      //   ?.map((item) => {
      //     console.log(
      //       "xxxxxxxxxxxxxxx iiiiiiiiiiiii ",
      //       item?.role,
      //       item?.partId,
      //       item?.partnerName,
      //       item?.notes
      //     );
      //   });

      if (parsedData.status === "SUCCESS") {
        set({
          subPartners: getPartnersIds(parsedData.subPartners)?.map(
            ({ ...item } = {}) => {
              // fixme after testing
              return item;
            }
          ),
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  login: async (agentCode) => {
    set({ loginLoading: true });
    try {
      const response = await api.authenticate({
        partnerAuthKey: agentCode,
      });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        try {
          const response = await api.loggedPartnerDataReq({});
          const parsedData = response.data;
          if (parsedData.status === "SUCCESS") {
            sessionStorage.setItem("userData", JSON.stringify(parsedData));
            sessionStorage.setItem("agentCode", agentCode);
            set({
              loginLoading: false,
              data: parsedData,
              currentUser: parsedData.partId,
              role: parsedData.role,
              agentCode: agentCode,
            });
          }
        } catch (error) {
          console.error("Error: ", error);
        }
        try {
          // const resp = await api.getPokerEyeVersion();
          // const parsedData = resp.data;
          // if (parsedData.status === "SUCCESS") {
          //   set({ apkUrl: parsedData.url });
          //   sessionStorage.setItem("apkUrl", parsedData.url);
          // }
          set({ apkUrl: `${window.location.origin}/api/getPokerEye` });
          sessionStorage.setItem(
            "apkUrl",
            `${window.location.origin}/api/getPokerEye`
          );
        } catch (error) {
          console.error("Error: ", error);
        }
      }
    } catch (error) {
      console.error("Error: ", error);
    }
    set({ loginLoading: false });
  },
  getUserData: async () => {
    try {
      const response = await api.loggedPartnerDataReq({});
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        try {
          window?.sessionStorage?.setItem("userData", JSON.stringify(parsedData));
        } catch (err) {
          //
        }
        set({
          data: parsedData,
          currentUser: parsedData.partId,
          role: parsedData.role,
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  isLoggedIn: async () => {
    try {
      await api.isLoggedIn();
    } catch (error) {
      // console.error("Error: ", error);
    }
  },
  setNewAgentCode: (newCode) => {
    set({ agentCode: newCode });
    sessionStorage.setItem("agentCode", newCode);
  },
  logout: async (reason = "") => {
    try {
      const response = await api.clearCookies({
        reason,
      });
      if (response.data.status === "SUCCESS") {
        sessionStorage.clear();
        return response.data.status;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  setCurrentUser: (partId, role) => {
    set({ currentUser: partId, role });
  },
  resetUserData: () => {
    sessionStorage.clear();
    set({ data: null });
  },
  getCurrentUser: () => get().currentUser,
  setRole: (role) => set({ role: role }),
}));

sessionStore.current = useSessionStore;
