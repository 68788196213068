import { useCallback } from "react";

import { DwnlSessionsReqV2 } from "@/api/Api";
import { api } from "@/shared/constants";

export function useDownloadSessionsCsv() {
  return useCallback(async (query: DwnlSessionsReqV2) => {
    try {
      const response = await api.dwnlSessionsReqV2(query);
      let fileData;
      if (typeof response?.data === "string") {
        fileData = new Blob([response?.data], {
          type: "text/csv",
        });
      } else {
        fileData = await (response?.data as any)?.blob();
      }
      const fileUrl = window.URL.createObjectURL(fileData);
      const downloadLink = document.createElement("a");
      downloadLink.href = fileUrl;
      downloadLink.download = `${query?.dateStarted} - ${query?.dateEnded}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(fileUrl);
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  }, []);
}
