import React, { useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Header from "@/components/Header";
import { useSessionStore } from "@/entities/session/model/session-store";
import { useUserAuthAxiosInterceptor } from "@/entities/user/use-user-auth-axios-interceptor";
import { useHeaderStore } from "@/store/HeaderStore";

export const AppLayout = () => {
  const sessionStore = useSessionStore();
  const { isLoggedIn, getUserData, getSubpartnersReq } = sessionStore;

  useUserAuthAxiosInterceptor();

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "visible") {
      isLoggedIn();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (window?.sessionStorage.getItem("agentCode")) {
      // refresh saved from last session
      getUserData();
    }
  }, [getUserData]);

  useEffect(() => {
    try {
      window?.addEventListener("focus", handleVisibilityChange);
      document.addEventListener("visibilitychange", handleVisibilityChange);
    } catch (err) {
      //
    }
    return () => {
      window?.removeEventListener("focus", handleVisibilityChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  // fixme extract
  const {
    setCurrentUser: headerSetCurrentUser,
    fetchBalance: headerFetchBalance,
    setPartnerSelection: headerSetPartnerSelection,
    getAllowedRoomsReq: headerGetAllowedRoomsReq,
  } = useHeaderStore();

  useEffect(() => {
    if (sessionStore.currentUser) {
      headerSetCurrentUser(sessionStore.currentUser);
      headerFetchBalance();
      headerSetPartnerSelection();
      headerGetAllowedRoomsReq();
    }
  }, [
    headerFetchBalance,
    headerGetAllowedRoomsReq,
    headerSetCurrentUser,
    headerSetPartnerSelection,
    sessionStore.currentUser,
  ]);

  // get subpartners
  useEffect(() => {
    if (sessionStore.currentUser) {
      // fixme maybe not needed if currentUser is subpartner
      getSubpartnersReq();
    }
  }, [sessionStore.currentUser, getSubpartnersReq]);

  if (sessionStore.data?.status === "SUCCESS") {
    return (
      <>
        <Header />
        <div className="content-bl">
          <Outlet />
        </div>
      </>
    );
  } else {
    return <Outlet />;
  }
};
