import { useMemo } from "react";

import { useSessionStore } from "@/entities/session/model/session-store";

export function useCurrentPartnerInfo() {
  const { currentUser: partId, subPartners } = useSessionStore();
  const { data: loggedInUser } = useSessionStore();
  return useMemo(() => {
    return (
      subPartners?.find((sub) => sub?.partId === partId) || {
        partId: loggedInUser?.partId,
        name: loggedInUser?.partnerName,
        role: loggedInUser?.role,
      }
    );
  }, [
    loggedInUser?.partId,
    loggedInUser?.partnerName,
    loggedInUser?.role,
    partId,
    subPartners,
  ]);
}
