import React from "react";

import { usePartnersStore } from "@/entities/partner/model/partners-store";
import { useSessionsDefaultFiltersOptions } from "@/entities/session/model/use-sessions-default-filters-options";
import { FilterDatePicker } from "@/shared/ui/filter-date-picker";

export function PartnersDateStarted({ ...props }) {
  const defaults = useSessionsDefaultFiltersOptions();
  const { searchParams, setSearchParam, setSearchParams } = usePartnersStore();
  return (
    <FilterDatePicker
      // defaultValue={defaults?.dateStarted?.option}
      placeholderText="Date from"
      id="dateStarted"
      name="dateStarted"
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      {...props}
    />
  );
}
