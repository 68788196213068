const deviceInfoTranslations = {
  en: {
    osType: "OS type",
    osVersion: "OS version",
    deviceModel: "Device model",
    phoneName: "Phone name",
    longitude: "Longitude",
    latitude: "Latitude",
    battery: "Battery",
    save: "Save",
    accept: "Accept",
  },
  zh: {
    osType: "OS 类型",
    osVersion: "OS版本",
    deviceModel: "设备型号",
    phoneName: "手机名称",
    longitude: "经度",
    latitude: "纬度",
    battery: "电量",
    save: "保存",
    accept: "接受",
  },
  ja: {
    osType: "OSの種類",
    osVersion: "OSバージョン",
    deviceModel: "端末モデル",
    phoneName: "デバイス名",
    longitude: "経度",
    latitude: "緯度",
    battery: "バッテリ",
    save: "保存",
    accept: "承諾",
  },
};

export default deviceInfoTranslations;
