import ReactDOM from "react-dom/client";

import "@/app/config/i18n-config";

import App from "./app/app";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <App />
    <div id="modal-root" />
  </>
);
