import { css } from "@emotion/react";
import { Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import DeviceModal from "@/components/modal/DeviceModal";
import { useAccountsStore } from "@/entities/account/model/accounts-store";
import { useSessionStore } from "@/entities/session/model/session-store";
import { useUserRole } from "@/entities/user/use-user-role";
import { getIsoDateString } from "@/shared/utils/date/get-iso-date-string";
import { getIsoTimeString } from "@/shared/utils/date/get-iso-time-string";
import { formatNumberWithSpaces } from "@/shared/utils/utils";
import { useAccountsFormStore } from "@/store/AccountsFormStore";

export const AccountsTableRow = ({
  account: {
    accId,
    index,
    account,
    notes = "",
    room,
    type,
    mode,
    status,
    hands,
    win,
    rake,
    insur,
    errors,
    version,
    lastGame,
    deviceInfo,
    state,
    ...row
  } = {},
}) => {
  const [note, setNote] = useState(notes);
  const [editingNote, setEditingNote] = useState(false);
  const { t } = useTranslation("accounts");
  const partId = useSessionStore().currentUser;
  const accountsStore = useAccountsStore();

  const updateNote = (accId, newNote) => {
    accountsStore.updateNote(accId, newNote).then(() => {
      setEditingNote(false);
      accountsStore.fetchAccounts(partId);
    });
    // setEditingNote(false)
    console.log("partId: ", partId, "accId:", accId, " НОВАЯ ЗАМЕТКА: ", newNote); // TODO Добавить запрос на обновление Notes
  };

  const [selectedModal, setSelectedModal] = useState(null);

  const openModal = (modalName) => {
    setSelectedModal(modalName);
  };

  const closeModal = () => {
    setSelectedModal(null);
    document.body.classList.remove("ReactModal__Body--open");
  };
  Modal.setAppElement("#root");

  const level = useMemo(() => {
    const value = parseInt(row?.level);
    return isNaN(value) ? "-" : value;
  }, [row?.level]);

  const role = useUserRole();
  const isAdminManager = role === "ADMIN" || role === "MANAGER";

  return (
    <>
      <div className="table-tr">
        <div>{index + 1}</div>
        <div>
          <input
            type="checkbox"
            className="select-item"
            checked={
              accountsStore.getSelectedAccounts().find((item) => item === accId) ||
              false
            }
            onChange={() => {
              accountsStore.handleToggleAccount(accId);
            }}
          />
        </div>
        <div>{formatNumberWithSpaces(account)}</div>
        <div className="editable-note">
          {editingNote ? (
            // fixme extract
            <input
              type="text"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
              onBlur={() => updateNote(accId, note)}
              onKeyDown={(event) => {
                if (event?.key === "Enter") {
                  updateNote(accId, note);
                }
              }}
              maxLength={24}
              autoFocus
            />
          ) : /*fixme use client rec*/
          note?.length ? (
            <Tooltip title={note} placement="right" arrow>
              <span
                onClick={() => setEditingNote(true)}
                css={css`
                  &&& {
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                `}
              >
                {note}
              </span>
            </Tooltip>
          ) : (
            <span
              onClick={() => setEditingNote(true)}
              css={css`
                &&& {
                  max-width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              `}
            >
              {note}
            </span>
          )}
        </div>
        <div>{room}</div>
        <div>
          <div className="indicator-wrapper">
            <div className={`indicator ${state ? "" : "disabled-indicator"}`} />
            {state ? t("online") : t("offline")}
          </div>
        </div>
        <div>{type}</div>
        <div>{mode}</div>
        <div>{level}</div>
        <div>{status}</div>
        <div>{hands?.toLocaleString()}</div>
        <div>{win?.toLocaleString()}</div>
        <div>{rake?.toLocaleString()}</div>
        <div>{insur?.toLocaleString()}</div>
        <div>{errors?.toLocaleString()}</div>
        <div>{version}</div>
        <div>
          {lastGame ? (
            <>
              <div> {getIsoDateString(lastGame)}</div>
              <div> {getIsoTimeString(lastGame)}</div>
            </>
          ) : (
            "-"
          )}
        </div>
        {isAdminManager && (
          <div>
            <span className="dev-info" onClick={() => openModal("device")}>
              {deviceInfo}
            </span>
          </div>
        )}
      </div>
      <Modal
        isOpen={selectedModal === "device"}
        onRequestClose={() => {
          closeModal();
          useAccountsFormStore.setState({ formValues: null });
        }}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "device" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <DeviceModal accId={accId} closeModal={closeModal} />
      </Modal>
    </>
  );
};
