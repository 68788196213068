import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

import { useSessionStore } from "@/entities/session/model/session-store";
import { useUserRole } from "@/entities/user/use-user-role";
import { DownloadSessionsCsvButton } from "@/pages/sessions/download-sessions-csv-button";
import { SessionsAccountFilter } from "@/pages/sessions/filters/sessions-account-filter";
import { SessionsDateEndedFilter } from "@/pages/sessions/filters/sessions-date-ended-filter";
import { SessionsDateStartedFilter } from "@/pages/sessions/filters/sessions-date-started-filter";
import { SessionsRoomFilter } from "@/pages/sessions/filters/sessions-room-filter";
import { SessionsRoomTypeFilter } from "@/pages/sessions/filters/sessions-room-type-filter";
import { SessionsLoadMoreButton } from "@/pages/sessions/sessions-load-more-button";
import { FilterCheckBox } from "@/shared/ui/filter-check-box";
import { getIsoDateString } from "@/shared/utils/date/get-iso-date-string";
import { getIsoTimeString } from "@/shared/utils/date/get-iso-time-string";
import { formatNumberWithSpaces, smoothScrollToBottom } from "@/shared/utils/utils";
import { useSessionPageStore } from "@/store/session-page-store";

import { SessionsPartnerFilter } from "./filters/sessions-partner-filter";

export function SessionsPage() {
  const { t } = useTranslation("sessions");
  const sessionPageStore = useSessionPageStore();

  const {
    loading,
    getAllowedRooms,
    fetchAccounts,
    fetchSessions,
    searchParams,
    setSearchParam,
    setSearchParams,
  } = useSessionPageStore();

  const role = useUserRole();
  const isAdminManager = role === "ADMIN" || role === "MANAGER";

  const { currentUser } = useSessionStore();

  const simpleBarRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    const scrollElement = simpleBarRef.current.getScrollElement();
    // fixme
    smoothScrollToBottom(scrollElement);
  }, []);

  useEffect(() => {
    // fixme to be removed
    if (searchParams?.partIds || searchParams?.partIds === null) {
      getAllowedRooms(searchParams?.partIds);
      fetchAccounts(searchParams?.partIds);
    } else {
      setSearchParams({
        partIds: currentUser,
      });
    }
  }, [
    currentUser,
    fetchAccounts,
    getAllowedRooms,
    searchParams?.partIds,
    setSearchParams,
  ]);

  useEffect(() => {
    // fixme
    if (searchParams?.partIds !== undefined) {
      fetchSessions();
    }
  }, [searchParams]);

  return (
    <SimpleBar
      ref={simpleBarRef}
      className="content sessions"
      forceVisible="y"
      autoHide={false}
    >
      <div style={{ position: "relative" }}>
        <div
          className="table-filters"
          css={css`
            &&& {
              //border: 1px solid blue;
              align-items: center;
            }
          `}
        >
          <FormContainer
            FormProps={{
              style: {
                display: "flex",
                // border: "1px solid red",
                flex: 1,
              },
            }}
          >
            {/*<Stack*/}
            {/*  direction="row"*/}
            {/*  alignItems="center"*/}
            {/*  css={css`*/}
            {/*    &&& {*/}
            {/*      margin-right: 8px;*/}
            {/*      width: 184px;*/}
            {/*    }*/}
            {/*  `}*/}
            {/*>*/}
            {/*  <SessionsPartnerFilterNew />*/}
            {/*</Stack>*/}
            {/*<Stack*/}
            {/*  direction="row"*/}
            {/*  alignItems="center"*/}
            {/*  css={css`*/}
            {/*    &&& {*/}
            {/*      margin-right: 8px;*/}
            {/*      width: 184px;*/}
            {/*    }*/}
            {/*  `}*/}
            {/*>*/}
            {/*  <SessionsPartnerFilterNew1 />*/}
            {/*</Stack>*/}
            <Stack direction="row" alignItems="center" gap="12px">
              <SessionsPartnerFilter name="partIds" disabled={loading} />
              <SessionsAccountFilter name="deviceId" disabled={loading} />
              <SessionsRoomFilter name="room" disabled={loading} />
              <SessionsRoomTypeFilter name="gameType" disabled={loading} />
              <SessionsDateStartedFilter disabled={loading} />
              <SessionsDateEndedFilter disabled={loading} />
            </Stack>
            <Stack
              flex={1}
              direction="row"
              alignItems="center"
              justifyContent="end"
            >
              {isAdminManager && (
                <FilterCheckBox
                  name="showEmpty"
                  disabled={loading}
                  searchParams={searchParams}
                  setSearchParam={setSearchParam}
                  setSearchParams={setSearchParams}
                >
                  {t("empty")}
                </FilterCheckBox>
              )}
              <FilterCheckBox
                name="onlyActive"
                disabled={loading}
                searchParams={searchParams}
                setSearchParam={setSearchParam}
                setSearchParams={setSearchParams}
              >
                {t("active")}
              </FilterCheckBox>
            </Stack>
          </FormContainer>
          <DownloadSessionsCsvButton
            partIds={[searchParams?.partIds]}
            dateStarted={searchParams?.dateStarted}
            dateEnded={searchParams?.dateEnded}
            deviceId={searchParams?.deviceId}
            room={searchParams?.room}
            gameType={searchParams?.gameType}
            isLoading={loading}
          />
        </div>
        <div className="content-table">
          {sessionPageStore.sessions.length ? (
            <div className="table-th">
              <div>{t("accounts")}</div>
              <div>{t("pid")}</div>
              <div>{t("room")}</div>
              <div>{t("aid")}</div>
              <div>{t("cid")}</div>
              <div>{t("type")}</div>
              <div>{t("sessionTime")}</div>
              <div>{t("fuelBilled")}</div>
              <div>{t("hands")}</div>
              <div>{t("level")}</div>
              <div>{t("win")}</div>
              <div>{t("rake")}</div>
              <div>{t("insur")}</div>
              <div>{t("errors")}</div>
              <div>{t("server")}</div>
            </div>
          ) : (
            ""
          )}
          {sessionPageStore.sessions.map((item, index) => {
            return (
              <div className="table-tr" key={index}>
                {/*<div>{`${index} - ${formatNumberWithSpaces(*/}
                {/*  item.session.deviceId*/}
                {/*)}`}</div>*/}
                <div>{formatNumberWithSpaces(item.session.deviceId)}</div>
                <div>{item.session.pid}</div>
                <div>{item.session.lastRoom}</div>
                <div>{item.session.alliance}</div>
                <div>{item.session.club}</div>
                <div>{item.session.gameType}</div>
                <div>
                  {/*<div>*/}
                  {/*  {formatSessionTime(item.session.started, item.session.ended)}*/}
                  {/*</div>*/}
                  <div>
                    {getIsoDateString(item?.session?.started)}&nbsp;
                    {getIsoTimeString(item?.session?.started)}
                  </div>
                  {item?.session?.ended && (
                    <div>
                      {getIsoDateString(item?.session?.ended)}&nbsp;
                      {getIsoTimeString(item?.session?.ended)}
                    </div>
                  )}
                </div>
                <div>{item.session.billed.toFixed(2)}</div>
                <div>{item.session.handsCnt}</div>
                <div>{item.session.lastBb.split(".")[0]}</div>
                <div>{item.session.winlose}</div>
                <div>{item.session.rake}</div>
                <div>{item.session.insurance}</div>
                <div>{item.totalErrors}</div>
                <div>{item.session.server}</div>
              </div>
            );
          })}
        </div>
        {sessionPageStore.currentPage < sessionPageStore.totalPages - 1 && (
          <div className="more">
            <SessionsLoadMoreButton scrollToBottom={scrollToBottom} />
          </div>
        )}
      </div>
    </SimpleBar>
  );
}
