import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, NavLinkProps } from "react-router-dom";

export function HeaderAccountsLink({ ...props }: Omit<NavLinkProps, "to">) {
  const { t } = useTranslation("header");
  return (
    <NavLink
      id="accountsLink"
      to="/accounts"
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
      {...props}
    >
      {t("accounts")}
    </NavLink>
  );
}
