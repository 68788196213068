import { css } from "@emotion/react";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import Select from "@/components/Select";
import { useConfigStore } from "@/entities/config/model/config-store";
// fixme
import s from "@/entities/config/ui/config-modal.scss";

import { TempInputLabel } from "../temp-input-label";
import { DynamicInputRange } from "./dynamic-input-range";

const stringToBoolean = (str) => str === "1";
const booleanToString = (bool) => (bool ? "1" : "0");

export const DynamicInput = ({
  isDisabled = false,
  configItem,
  config,
  getError,
  setError,
  removeError,
}) => {
  const { t } = useTranslation("config");

  const userValue = useConfigStore((state) => state.userValues[configItem.confId]);
  const setUserValue = useConfigStore((state) => state.setUserValue);

  const userValues = useConfigStore((state) => state.userValues);

  const defaultRangeValues = configItem.defaultValue?.split(";").map(Number);
  const userRangeValues = userValue?.split(";").map(Number);

  const configValue =
    configItem.type === "boolean"
      ? stringToBoolean(
          userValue !== undefined ? userValue : configItem.defaultValue
        )
      : userValue !== undefined
      ? userValue
      : configItem.defaultValue;

  const isParentActive = () => {
    if (!configItem.parentConfId) return true;
    const parentItem = config.find(
      (item) => item.confId === configItem.parentConfId
    );
    if (parentItem && parentItem.type === "header") {
      return true;
    }
    const parentValue = userValues[configItem.parentConfId];

    if (parentItem?.type === "boolean") {
      if (parentValue === undefined || parentValue === null) {
        return stringToBoolean(parentItem?.defaultValue);
      }
    }

    return parentItem && parentValue === parentItem.activeValue;
  };

  const disabled = isDisabled || !isParentActive();

  // useEffect(() => {
  //   onDisabledChange(configItem.confId, disabled);
  // }, [configItem.confId, disabled, onDisabledChange]);

  useEffect(() => {
    if (configItem?.name === "show_tips_enable") {
      const parentItem = config.find(
        (item) => item.confId === configItem.parentConfId
      );
    }
  }, [config, configItem, configValue, userValues]);

  const handleOptionSelect = (selectedOption) => {
    setUserValue(configItem.confId, selectedOption.value);
  };

  const handleInputChange = (value) => {
    setUserValue(configItem.confId, value);
  };

  const handleCheckboxChange = (isChecked) => {
    setUserValue(configItem.confId, booleanToString(!isChecked));
  };

  const [minValue, maxValue] = userRangeValues || defaultRangeValues;

  // fixme use form container and validation
  const elementRef = useRef(null);

  const isAutoRebuyEnabled = useMemo(() => {
    return !!Number(
      userValues?.[
        String(
          config?.find((item) => {
            return item?.name === "auto_rebuy";
          })?.confId
        )
      ]
    );
  }, [config, userValues]);
  const isBuyInClubId = useMemo(() => {
    return configItem?.name === "buyin_club_id";
  }, [configItem?.name]);
  const buyInClubIdValue = useMemo(() => {
    return userValues?.[
      String(
        config?.find((item) => {
          return item?.name === "buyin_club_id";
        })?.confId
      )
    ];
  }, [config, userValues]);

  const minMoreMaxError = useMemo(() => minValue > maxValue, [maxValue, minValue]);
  const buyInClubIdRequiredError = useMemo(() => {
    return isAutoRebuyEnabled && isBuyInClubId && !buyInClubIdValue;
  }, [buyInClubIdValue, isAutoRebuyEnabled, isBuyInClubId]);

  useEffect(() => {
    if (minMoreMaxError) {
      setError({
        fieldName: configItem?.name,
        message: "MIN > MAX!",
        elementRef,
      });
      return;
    }
    if (buyInClubIdRequiredError) {
      setError({
        fieldName: configItem?.name,
        message: "required",
        elementRef,
      });
      return;
    }
    removeError({ fieldName: configItem?.name });
  }, [
    buyInClubIdRequiredError,
    configItem?.name,
    minMoreMaxError,
    removeError,
    setError,
  ]);

  const error = useMemo(() => {
    return getError({ fieldName: configItem?.name });
  }, [configItem?.name, getError]);

  switch (configItem.type) {
    case "brain":
    case "stats_profile":
    case "options":
      // eslint-disable-next-line no-case-declarations
      const options = Object.entries(configItem.possibleValues).map(
        ([value, label]) => {
          return {
            value,
            label: t(label?.name || label),
            ...(label?.priceAdd && {
              hint: label?.priceAdd,
            }),
          };
        }
      );
      return (
        <div className={s.selectField}>
          <Select
            disabled={disabled}
            options={options}
            onSelect={handleOptionSelect}
            selected={options.find((option) => option.value === configValue)}
            gray
          />
        </div>
      );
    case "int":
    case "string":
      return (
        <TempInputLabel
          errorMessage={error?.message}
          disabled={disabled}
          css={css`
            &&& {
              flex: 1;
            }
          `}
        >
          <div
            className={`${s.interactiveField} ${disabled && s.disabled}`}
            ref={elementRef}
          >
            <input
              disabled={disabled}
              autoFocus
              className={`${s.input} interactiveInput`}
              type="text"
              value={configValue || ""}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </div>
        </TempInputLabel>
      );
    case "boolean":
      return (
        <>
          <div className={s.interactiveField}>
            <div className={s.checkboxContainer}>
              <label>
                <input
                  disabled={disabled}
                  className="on-off"
                  type="checkbox"
                  checked={configValue}
                  onChange={() => handleCheckboxChange(configValue)}
                />
              </label>
              <span className={s.checkboxPercent}>
                {configItem.priceAdd > 0 && `+${configItem.priceAdd} %`}
              </span>
            </div>
          </div>
        </>
      );
    case "range":
      return (
        <TempInputLabel errorMessage={error?.message} disabled={disabled}>
          <DynamicInputRange
            ref={elementRef}
            configItem={configItem}
            disabled={disabled}
            error={error}
          />
        </TempInputLabel>
      );
    default:
      return null;
  }
};
