const accountsTranslations = {
  en: {
    accs: "Accounts",
    notes: "Notes",
    onOff: "On/Off",
    room: "Room",
    state: "State",
    online: "Online",
    offline: "Offline",
    type: "Type",
    mode: "Mode",
    level: "Level",
    status: "Status",
    hands: "Hands",
    win: "Win",
    rake: "Rake",
    insur: "Insur",
    errs: "Errs",
    version: "Version",
    lastGame: "Last game",
    deviceInfo: "Device info",
  },
  zh: {
    accs: "账号",
    notes: "备注",
    onOff: "开/关",
    room: "平台",
    state: "状态",
    online: "在线",
    offline: "离线",
    type: "类型",
    mode: "模式",
    level: "级别",
    status: "状态",
    hands: "手数",
    win: "战绩",
    rake: "抽水",
    insur: "保险",
    errs: "误差",
    version: "版本",
    lastGame: "最后游戏",
    deviceInfo: "设备信息",
  },
  ja: {
    accs: "アカウント",
    notes: "メモ",
    onOff: "オン/オフ",
    room: "ルーム",
    state: "状態",
    online: "オンライン",
    offline: "オフライン",
    type: "タイプ",
    mode: "モード",
    level: "レベル",
    status: "ステータス",
    hands: "ハンド数",
    win: "ウィンレート",
    rake: "レーキ",
    insur: "インシュランス",
    errs: "エラー",
    version: "バージョン",
    lastGame: "ラストゲーム",
    deviceInfo: "デバイス情報",
  },
};

export default accountsTranslations;
