import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useConfigStore } from "@/entities/config/model/config-store";
import { ConfigItemLockButton } from "@/entities/config/ui/config-item-lock-button";
import { useUserRole } from "@/entities/user/use-user-role";
import { DynamicInput } from "@/shared/ui/dynamic-input/dynamic-input";

import { ConfigItemForceSaveSwitch } from "./config-item-force-save-switch";
// fixme
import s from "./config-modal.scss";

export const DynamicInputList = ({
  isLoading = false,
  config,
  getError,
  setError,
  removeError,
  queryKey,
}) => {
  const { t } = useTranslation("config");
  const { t: d } = useTranslation("descr");

  const { userValues } = useConfigStore();

  const role = useUserRole();
  const isAdminOrManager = role === "ADMIN" || role === "MANAGER";

  return (
    <>
      {config?.map((configItem) => {
        const isParent = config.some(
          (item) => item.parentConfId === configItem.confId
        );
        const isFirstLevelItem = !configItem?.parentConfId;
        const configItemParent = config?.find(
          (config) => config?.confId === configItem?.parentConfId
        );
        const isDisabled =
          isLoading ||
          !!(
            configItemParent &&
            configItemParent?.type !== "header" &&
            userValues?.[configItemParent?.confId] !== configItemParent?.activeValue
          );

        return (
          <Stack
            key={configItem?.confId}
            direction="row"
            alignItems="center"
            gap="0.5rem"
            css={css`
              &&& {
                border-radius: 0.5rem;
                //border: 1px dotted green;
                //overflow: hidden;
                padding: 0 0 0 0.5rem;
                /*background-color: ${isParent
                  ? "rgba(125, 67, 201, 0.03)"
                  : "transparent"};*/
                background-color: transparent;
                position: relative;
              }
            `}
          >
            {isAdminOrManager && !!configItem?.adminOnly && (
              <Stack
                css={css`
                  &&& {
                    position: absolute;
                    top: 50%;
                    left: -1rem;
                    transform: translateY(-60%);
                  }
                `}
              >
                <ConfigItemLockButton
                  configItem={configItem}
                  disabled={isLoading}
                  queryKey={queryKey}
                />
              </Stack>
            )}
            <div
              className={`${
                isParent || isFirstLevelItem
                  ? s.itemContainerParent
                  : s.itemContainer
              } ${isDisabled ? s.disabled : ""}`}
              css={css`
                flex: 1;
                &&& {
                  min-height: 30px;
                  border-radius: 16px !important;
                  background-color: transparent;
                  //padding: 0 0.2rem 0 0.5rem;
                }
              `}
            >
              <div
                css={css`
                  &&& {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    text-align: left;
                  }
                `}
              >
                <Stack direction="row" alignItems="center" gap="0.5rem">
                  <span
                    className={`${s.itemTitle} ${
                      isParent ? s.parentTitle : ""
                    } inputTitle`}
                  >
                    {t(configItem?.name)}
                  </span>
                </Stack>
                <span className={`${s.itemDescr} inputTitle`}>
                  {configItem?.descr !== "" && d(configItem?.name)}
                </span>
              </div>

              {/*fixme*/}
              {/* if group input is null*/}
              {/* give grid cell a content to push reset switch to the right */}
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  //width: ${configItem?.name === "stats_profile"
                    ? "200%"
                    : "auto"};
                  margin-left: ${configItem?.name === "stats_profile"
                    ? "-4rem"
                    : "0"};

                  > div {
                    width: 100%;
                  }
                `}
              >
                <DynamicInput
                  isDisabled={isLoading}
                  configItem={configItem}
                  config={config}
                  getError={getError}
                  setError={setError}
                  removeError={removeError}
                />
              </div>
              {isFirstLevelItem && (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <ConfigItemForceSaveSwitch configItem={configItem} />
                </div>
              )}
            </div>
          </Stack>
        );
      })}
    </>
  );
};
