import { useMemo } from "react";

import { FilterSelect } from "@/shared/ui/filter-select";
import { useSessionPageStore } from "@/store/session-page-store";

export function SessionsRoomFilter({ options, ...props }) {
  const { searchParams, setSearchParam, setSearchParams } = useSessionPageStore();
  const sessionPageStore = useSessionPageStore();

  const roomOptions = useMemo(() => {
    if (!sessionPageStore.allowedRooms || !sessionPageStore.allowedRooms.rooms) {
      // fixme extract
      return [{ value: null, label: "All" }];
    }
    return [{ value: null, label: "All" }].concat(
      sessionPageStore.allowedRooms.rooms
    );
  }, [sessionPageStore.allowedRooms]);

  return (
    <FilterSelect
      placeholder="Room"
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      options={options || roomOptions}
      defaultValue={roomOptions?.[0]}
      {...props}
    />
  );
}
