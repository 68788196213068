import { useMemo } from "react";

import { useSessionStore } from "@/entities/session/model/session-store";

export function useCanBuyFuel() {
  const session = useSessionStore();
  return useMemo(() => {
    return (
      session?.role?.toUpperCase() === "MAINAGENT" ||
      session?.role?.toUpperCase() === "RETAILOWN"
    );
  }, [session?.role]);
}
