import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";

import { useAccountsStore } from "@/entities/account/model/accounts-store";
import { useSessionStore } from "@/entities/session/model/session-store";

import { AccountsTableHead } from "./accounts-table-head";
import { AccountsTableRow } from "./accounts-table-row";

export function AccountsPage() {
  const { currentUser } = useSessionStore();
  const { fetchAccounts, accounts } = useAccountsStore();

  useEffect(() => {
    fetchAccounts(currentUser);
  }, [currentUser, fetchAccounts]);

  return (
    <SimpleBar className="content accounts" forceVisible="y" autoHide={false}>
      <div>
        <div className="content-table">
          <AccountsTableHead />
          {accounts?.map((account, index) => {
            return (
              <AccountsTableRow
                key={account?.accId}
                account={{ index, ...account }}
              />
            );
          })}
        </div>
        {/*<Modal*/}
        {/*    isOpen={selectedModal === "device"}*/}
        {/*    onRequestClose={closeModal}*/}
        {/*    closeTimeoutMS={300}*/}
        {/*    className={`modal ${selectedModal === "device" ? "closing-effect" : ""}`}*/}
        {/*    overlayClassName='modal-overlay'>*/}
        {/*    <DeviceModal closeModal={closeModal}/>*/}
        {/*</Modal>*/}
      </div>
    </SimpleBar>
  );
}
