import { useColorScheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSessionStore } from "@/entities/session/model/session-store";

const DarkModeSwitch = () => {
  const { t } = useTranslation("header");
  const darkModeCookie = Cookies.get("isDarkMode");
  const sessionStore = useSessionStore();
  const [isDarkMode, setIsDarkMode] = useState(darkModeCookie === "1");

  const { setMode } = useColorScheme();

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
    Cookies.set("isDarkMode", !isDarkMode ? "1" : "0", {
      expires: 365,
    });
    //
    setMode(!isDarkMode ? "dark" : "light");
  };

  useEffect(() => {
    document.body.classList.toggle("dark", isDarkMode);
    sessionStore.setTheme(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <label>
      <span>{t("dark")}</span>
      <input
        type="checkbox"
        className="on-off"
        checked={isDarkMode}
        onChange={handleDarkModeToggle}
      />
    </label>
  );
};

export default DarkModeSwitch;
