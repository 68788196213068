import { Components, TextFieldProps } from "@mui/material";
import { DefaultTheme } from "@mui/private-theming";

export function inputPaddingFromSize(size: TextFieldProps["size"]) {
  return !size || size === "small"
    ? {
        padding: "5px 16px !important",
      }
    : size === "large" && {
        padding: "14px 16px",
      };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mixinLabelFont = (error = false, theme: DefaultTheme) => ({
  // ...(!error && {
  // color: theme?.palette?.grey_600?.main,
  // }),
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  "&, &.Mui-focused, &.Mui-error, & span.MuiFormLabel-asterisk": {
    // ...(!error && {
    // color: theme?.palette?.grey_600?.main,
    // }),
  },
});

export const MuiTextField: Components<DefaultTheme>["MuiTextField"] = {
  defaultProps: {
    variant: "outlined",
    size: "small",
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      return {
        "& .MuiFormLabel-root": {
          ...mixinLabelFont(ownerState?.error, theme),
          transform: `translate(0px, 0px) scale(1)`,
          marginBottom: "4px",
        },
        "& .MuiInputBase-root": {
          marginTop: ownerState.label ? theme.spacing(3) : 0,
          borderRadius: 8,
          "& input": {
            borderRadius: 8,
            backgroundColor: "var(--input-bg)",
            // position: "relative", // Убрал, т.к. ломает select
            fontWeight: 400,
            fontSize: "11px",
            lineHeight: "normal",
            ...inputPaddingFromSize(ownerState?.size),
          },
          "& textarea": {
            // position: "relative", // Убрал, т.к. ломает select
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",

            "&::placeholder": {
              // color: theme.palette.grey_300.main,
              opacity: 1,
            },
          },
          "&:hover fieldset": {
            ...(!ownerState?.disabled && {
              // borderColor: theme?.palette?.green_400?.main,
              borderColor: "transparent",
            }),
          },
          "&.Mui-focused fieldset": {
            // borderColor: theme?.palette?.green_600?.main,
            borderColor: "transparent",
          },
          "&.Mui-error.Mui-focused fieldset": {
            // borderColor: theme?.palette?.red_500?.main,
          },
          "& fieldset": {
            // borderColor: "rgba(255,255,255,1)",
            borderColor: "transparent",
            top: 0,
            "& legend": {
              display: "none",
            },
          },
        },
        "& .MuiFormHelperText-root": {
          ...mixinLabelFont(ownerState?.error, theme),
          marginLeft: 0,
          marginTop: theme.spacing(1),
          display: "inline-flex",
          alignItems: "center",
          "&:before": {
            display: "inline-block",
            height: "1rem",
            width: "1rem",
            content: `url(/icons/text-field/helper-text-icon.svg)`,
            marginRight: theme?.spacing(1),
          },
        },
      };
    },
  },
};
