import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { useAccountsStore } from "@/entities/account/model/accounts-store";
import { useSessionStore } from "@/entities/session/model/session-store";
import { api } from "@/shared/constants";

export function useConfigLastFilters({ accId: _accId } = {}, queryOptions) {
  const session = useSessionStore();
  const accountStore = useAccountsStore();
  const accId = useMemo(() => {
    return (
      session?.accId ||
      _accId ||
      // use only one
      (accountStore?.selectedAccounts?.length === 1 &&
        // order selected by table rows to correctly get first if multi
        accountStore?.accounts
          ?.map((acc) => acc?.accId)
          ?.filter((accId) => accountStore?.selectedAccounts?.includes(accId))?.[0])
    );
  }, [
    _accId,
    accountStore?.accounts,
    accountStore?.selectedAccounts,
    session?.accId,
  ]);
  return useQuery({
    queryKey: [`/api/accounts/cfgSelectorsReq`, accId],
    queryFn: () => {
      return api
        ?.cfgSelectorsReq({
          accId,
        })
        ?.then((res) => res?.data);
    },
    enabled: !!accId,
    ...queryOptions,
  });
}
