import { useCallback, useEffect, useState } from "react";

import { useAccountsStore } from "@/entities/account/model/accounts-store";
import { useSessionStore } from "@/entities/session/model/session-store";
import { formatLabelForHeader, formatNumberWithSpaces } from "@/shared/utils/utils";
import { useHeaderStore } from "@/store/HeaderStore";

import Select from "./Select";

export function HeaderPartnersSelect({ ...props }) {
  const sessionStore = useSessionStore();
  const headerStore = useHeaderStore();
  const accountsStore = useAccountsStore();

  const [selectOptions, setSelectOptions] = useState([
    {
      value: sessionStore.data.partId,
      label: formatNumberWithSpaces(sessionStore.data.partnerName),
      role: sessionStore.role,
    },
  ]);

  const handleSelect = useCallback(
    (option: any) => {
      sessionStore.setCurrentUser(option.value, option.role);
      // headerStore.setPartnerSelection(option.value);
      headerStore.resetBalanceHistory();
      // Сброс выбранных аккаунтов при смене пользователя
      accountsStore.resetSelectedAccounts();
    },
    [accountsStore, headerStore, sessionStore]
  );

  useEffect(() => {
    if (sessionStore.subPartners) {
      setSelectOptions(
        [
          {
            value: sessionStore.data.partId,
            label: formatNumberWithSpaces(sessionStore.data.partnerName),
            role: sessionStore.data.role,
          },
        ].concat(
          sessionStore.subPartners.map((item: any) => ({
            value: item.partId,
            label: formatLabelForHeader(item.name, item.note),
            role: item.role,
          }))
        )
      );
    }
  }, [sessionStore.subPartners]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <Select
      options={selectOptions}
      onSelect={handleSelect}
      selected={selectOptions.find(
        (item) => item.value === sessionStore.currentUser
      )}
    />
  );
}
