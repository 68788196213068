import { FilterDatePicker } from "@/shared/ui/filter-date-picker";
import { useSessionPageStore } from "@/store/session-page-store";

export function SessionsDateStartedFilter({ ...props }) {
  const { searchParams, setSearchParam, setSearchParams } = useSessionPageStore();
  return (
    <FilterDatePicker
      // defaultValue={defaults?.dateStarted?.option}
      placeholderText="Date from"
      id="dateStarted"
      name="dateStarted"
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      {...props}
    />
  );
}
