import { css } from "@emotion/react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Select, Stack } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import { useColorScheme } from "@mui/material/styles";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
// fixme
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as CN } from "round-flags/flags/cn.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as JP } from "round-flags/flags/jp.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as US } from "round-flags/flags/us.svg";

export const implementedTranslations = {
  en: {
    name: "EN",
    icon: US,
  },
  zh: {
    name: "CN",
    icon: CN,
  },
  ja: {
    name: "JA",
    icon: JP,
  },
};

// fixme refactor ugly styling
export function LanguageSelect() {
  const { i18n } = useTranslation();
  const { mode } = useColorScheme();
  const [open, setOpen] = React.useState(false);

  const value = useMemo(() => {
    return implementedTranslations?.[
      i18n?.language as keyof typeof implementedTranslations
    ]?.["name"];
  }, [i18n?.language]);

  return (
    <Select
      size="small"
      id="demo-customized-select"
      value={value}
      input={<InputBase />}
      open={open}
      IconComponent={() => null}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      MenuProps={{
        transitionDuration: 10,
        MenuListProps: {
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
            "& > li": {
              padding: "0.140rem 0 !important",
              gap: "0.4rem",
              minHeight: "1.8rem",
              backgroundColor: "rgba(0,0,0,0) !important",
              "&:hover": {
                color: "var(--accent-color)",
              },
              transition: "color 0.2s",
            },
          },
        },
        slotProps: {
          paper: {
            elevation: 0,
            style: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
              borderTop: "none",
              borderRight:
                mode === "light"
                  ? "var(--button-border)"
                  : "var(--button-border-dark)",
              borderBottom:
                mode === "light"
                  ? "var(--button-border)"
                  : "var(--button-border-dark)",
              borderLeft:
                mode === "light"
                  ? "var(--button-border)"
                  : "var(--button-border-dark)",
              backgroundColor: `${
                mode === "light" ? `#F6F6F9` : `#1F2228`
              } !important`,
              marginTop: "-1px",
            },
          },
        },
      }}
      css={css`
        &&& {
          min-height: 32px;
          transition: color 0.2s;
          .MuiInputBase-input {
            background-color: ${mode === "light"
              ? `#F6F6F9`
              : `#1F2228`} !important;
            font-size: 12px;
            border: ${mode === "light"
              ? "var(--button-border)"
              : "var(--button-border-dark)"};
            border-top-left-radius: ${open ? 0.5 : 2}rem;
            border-top-right-radius: ${open ? 0.5 : 2}rem;
            border-bottom-right-radius: ${open ? 0 : 2}rem;
            border-bottom-left-radius: ${open ? 0 : 2}rem;
            padding: 2px 0 2px 0;
            .MuiStack-root {
              flex-direction: ${open ? "row" : "row-reverse"};
            }
          }
          .MuiSelect-icon {
            display: ${open ? "none" : "flex"};
            position: static;
          }
          :hover {
            color: var(--accent-color);
          }
        }
      `}
    >
      {Object.entries(implementedTranslations)?.map(([key, value]) => {
        const Icon = value.icon as React.ElementType;
        return (
          <MenuItem
            key={key}
            value={
              implementedTranslations?.[
                key as keyof typeof implementedTranslations
              ]?.["name"]
            }
            sx={{
              display: key === i18n?.language ? "none" : "flex",
            }}
            onClick={() => {
              i18n.changeLanguage(key);
              // console.log("xxxxxxxxx aaaaaaaaaaaaaa ", i18n?.language);
              try {
                // apk?.updateLocale(i18n?.language);
              } catch (err) {
                //
              }
            }}
            css={css`
              &&& {
                border-top: 1px solid ${mode === "light" ? `#EAEBF0` : `#3A3B42`};
              }
            `}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap="0.15rem"
              css={css`
                &&& {
                  padding: 3px 6px;
                  > * {
                    width: 1.25rem;
                    height: 1.25rem;
                    //border: 1px dotted red;
                    border-radius: 50%;
                  }
                }
              `}
            >
              <Stack alignItems="center" justifyContent="center">
                {open ? (
                  <Icon
                    id="flag-icon"
                    css={css`
                      &&& {
                        height: 100%;
                        width: 100%;
                      }
                    `}
                  />
                ) : (
                  <KeyboardArrowDownRoundedIcon
                    css={css`
                      &&& {
                        height: 100%;
                        width: 100%;
                      }
                    `}
                  />
                )}
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                css={css`
                  &&& {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: normal;
                  }
                `}
              >
                <span
                  css={css`
                    &&& {
                      //border: 1px dotted red;
                      margin-left: 4px;
                    }
                  `}
                >
                  {
                    implementedTranslations?.[
                      key as keyof typeof implementedTranslations
                    ]?.["name"]
                  }
                </span>
              </Stack>
            </Stack>
          </MenuItem>
        );
      })}
    </Select>
  );
}
