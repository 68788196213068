import { Components } from "@mui/material";
import { DefaultTheme } from "@mui/private-theming";

export const MuiButton: Components<DefaultTheme>["MuiButton"] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => {
      return {
        borderRadius: 0,
        textTransform: "none",
      };
    },
  },
};
