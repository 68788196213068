const guideTranslations = {
  en: {
    back: "Back",
    next: "Next",
    balance: "Balance",
    toReplenishFuelPressThisButton: "To replenish fuel press this button.",
    getReadyToReplenishTheBalanceThroughACryptoWalletInCurrencyUSDT:
      "Get ready to replenish the balance through a crypto wallet in currency USDT.",
    inThisWindowYouSpecifyTheTopUpAmount:
      "In this window, you specify the top-up amount. Depending on the amount, we will give you a greater quantity of fuel as a bonus.",
    whenYouClickOnTheGiftAddButton:
      "When you click on the gift add button, both the contribution amount and the quantity of bonus fuel will increase for you.",
    history: "History",
    pleaseClickOnThisButtonToViewTheTopUpHistoryAndTransferHistory:
      "Please click on this button to view the top-up history and transfer history.",
    admin: "Admin",
    byClickingOnThisButtonYouCanChangeTheAdministratorSettings:
      "By clicking on this button, you can change the administrator settings - interface, language, download APK, configure Telegram, start the guide, and log out of your account.",
    switchBetweenLightAndDarkMode: "Switch between light and dark mode.",
    changeTheLanguage: "Change the language.",
    downloadTheAPK: "Download the APK.",
    setYourTelegramNicknameInTheAPKForPartners:
      "Set your Telegram nickname in the APK for partners.",
    startTheGuideReplay: "Start the guide replay.",
    logOutOfYourAccount: "Log out of your account.",
    configuration: "Configuration",
    thisWindowIsDesignedForAccountConfiguration:
      "This window is designed for account configuration, where you can select a club, game type, and mode, and customize them according to your preferences.",
    accounts: "Accounts",
    accountManagementWindow1:
      "Account management window. Here you can update APK, modify device info settings, and by clicking on the activity indicators ",
    accountManagementWindow2:
      "in the left part of the table, you can quickly configure your accounts there",
    sessions: "Sessions",
    inThisWindowYouCanViewYourSessions:
      "In this window, you can view your sessions. For better control of your achievements, please configure your account name, club, game type, and session time interval in advance.",
    partners: "Partners",
    retail: "Retail",
    forVIPpartners: "For VIP partners only.",
    hereYouWillBeAbleToAddNewPartners:
      "Here, you will be able to add new partners, view their statistics by selecting specific time intervals on the calendar, write notes for each partner to facilitate your orientation, manage their fuel, and review the top-up history for each partner individually.",
  },
  zh: {
    back: "返回上一步",
    next: "继续",
    balance: "燃料余额",
    toReplenishFuelPressThisButton: "如需要补充燃料，请按此按钮",
    getReadyToReplenishTheBalanceThroughACryptoWalletInCurrencyUSDT:
      "准备好通过USDT货币钱包充值余额",
    inThisWindowYouSpecifyTheTopUpAmount:
      "在这个窗口可以指定充值金额。购买更多燃料可享受额外赠送",
    whenYouClickOnTheGiftAddButton:
      "点击添加赠送按钮后，您的存款金额和燃料奖励将同步增加",
    history: "历史记录",
    pleaseClickOnThisButtonToViewTheTopUpHistoryAndTransferHistory:
      "请点击此按钮查看您的充值和转账记录",
    admin: "管理员",
    byClickingOnThisButtonYouCanChangeTheAdministratorSettings:
      "通过点击此按钮可更改界面，语言，Telegram账号。包括下载APK，查看操作指南，复制重置权限码 和退出登录",
    switchBetweenLightAndDarkMode: "明暗模式之间切换",
    changeTheLanguage: "选择语言",
    downloadTheAPK: "下载APK",
    setYourTelegramNicknameInTheAPKForPartners: "设置 Telegram 账户",
    startTheGuideReplay: "启动指南",
    logOutOfYourAccount: "退出登录",
    configuration: "设置",
    thisWindowIsDesignedForAccountConfiguration:
      "此窗口用于账户配置，您可以在此选择俱乐部 游戏类型和模式，并根据需求进行自定义设置",
    accounts: "账号",
    accountManagementWindow1:
      "账号管理窗口可以更新APK，修改设备信息设置，并通过单击",
    accountManagementWindow2: "在左侧可以快速设置账号",
    sessions: "牌局",
    inThisWindowYouCanViewYourSessions:
      "在此窗口您可以查看牌局。 为了更好的管理您的成就 请提前配置您的账户名称，俱乐部，游戏类型和牌局时间",
    partners: "合伙人",
    retail: "零售",
    forVIPpartners: "仅限VIP合伙人",
    hereYouWillBeAbleToAddNewPartners:
      "在这里您能添加新的用户。通过在日历上选择特定的时间段查看他们的统计数据。为每个用户添加备注，管理他们的燃料，并单独查看每个用户的充值记录",
  },
  ja: {
    back: "戻る",
    next: "次へ",
    balance: "残高",
    toReplenishFuelPressThisButton:
      "燃料を補給するためにこのボタンを押してください",
    getReadyToReplenishTheBalanceThroughACryptoWalletInCurrencyUSDT:
      "USDT通貨の暗号財布を通じて燃料を補給する",
    inThisWindowYouSpecifyTheTopUpAmount:
      "このページでトップアップの金額を指定することができます。金額によって、ボーナスとしてもっと燃料を贈ります",
    whenYouClickOnTheGiftAddButton:
      "ギフトを追加するというボタンを押すと、負担金額もボーナスの金額も増加します",
    history: "履歴",
    pleaseClickOnThisButtonToViewTheTopUpHistoryAndTransferHistory:
      "トップアップ履歴と転送履歴をチェックするためにこのボタンを押してください",
    admin: "管理者",
    byClickingOnThisButtonYouCanChangeTheAdministratorSettings:
      "このボタンを押すと、インタフェース、言語、APKダウンロード、Telegram構成、ガイドスタート、ログアウトなど管理者の設定を変更することができます",
    switchBetweenLightAndDarkMode: "ライトモードとダークモードを切り替える",
    changeTheLanguage: "言語を変更する",
    downloadTheAPK: "APKをダウンロードする",
    setYourTelegramNicknameInTheAPKForPartners:
      "Telegramのニックネームを設定してパートナーに見せる",
    startTheGuideReplay: "ガイド再生を開始する",
    logOutOfYourAccount: "アカウントからログアウトする",
    configuration: "構成",
    thisWindowIsDesignedForAccountConfiguration:
      "アカウント構成のページでは、お好みに合わせて、クラブ、ゲームタイプ、モードなどをカスタマイズすることができます",
    accounts: "アカウント",
    accountManagementWindow1:
      "アカウント管理ページです。 ここでAPKやデバイス情報設定を更新することができます。",
    accountManagementWindow2:
      "または、テーブルの左側にある動作インジケーターを押すと、アカウントをすばやく構成することができます",
    sessions: "セッション",
    inThisWindowYouCanViewYourSessions:
      "ここでセッションを見ることはできます。 実績をコントロールするために、アカウントの名前、クラブ、ゲームタイプやセッション時間を予め設定してください",
    partners: "パートナー",
    retail: "小売業者",
    forVIPpartners: "VIPパートナーのみ",
    hereYouWillBeAbleToAddNewPartners:
      "ここで、新しいパートナーを誘ったり、カレンダーで一定の時間間隔を選択したらパートナーの統計情報を見たり、位置確認をサポートさせるメモを書いたり、パートナーごとに燃料量を経営したり、トップアップ履歴を確認したりすることができます",
  },
};

export default guideTranslations;
