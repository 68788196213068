import { css } from "@emotion/react";
import { Stack, StackProps } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import { useCallback } from "react";

import { useConfigQueryKey } from "@/entities/config/model/use-config";
import { useSessionStore } from "@/entities/session/model/session-store";
import { api } from "@/shared/constants";
import { toastError } from "@/shared/utils/toast-error";

export function ConfigItemLockButton({
  disabled = false,
  configItem,
  queryKey,
  ...props
}: StackProps & {
  disabled?: boolean;
  configItem: any;
  queryKey?: any;
}) {
  const queryClient = useQueryClient();

  const session = useSessionStore();

  const toggleConfigItemLock = useCallback(
    (configs: any) => {
      const newConfigs = cloneDeep(configs);
      Object.keys(newConfigs?.cfgs || {})?.forEach((key) => {
        const item = (
          (newConfigs?.cfgs?.[key as keyof typeof newConfigs] || []) as Array<any>
        )?.find((item: any) => item?.confId === configItem?.confId);
        if (item) {
          item.adminOnlyUnlocked = !item?.adminOnlyUnlocked;
        }
      });
      return newConfigs;
    },
    [configItem?.confId]
  );

  const onClick = useCallback(async () => {
    queryClient?.setQueryData([useConfigQueryKey, queryKey], toggleConfigItemLock);
    try {
      const res = await api?.lockUnlockAdminCfgReq({
        partId: session?.currentUser,
        confId: configItem?.confId,
        unlock: !configItem?.adminOnlyUnlocked,
      });
      if (res?.data?.status?.toUpperCase() === "ERROR") {
        throw res.data;
      }
    } catch (err: any) {
      toastError(err);
      queryClient?.setQueryData(
        [useConfigQueryKey, queryKey],
        toggleConfigItemLock
      );
    }
  }, [
    configItem?.adminOnlyUnlocked,
    configItem?.confId,
    queryClient,
    queryKey,
    session?.currentUser,
    toggleConfigItemLock,
  ]);

  return (
    <Stack
      onClick={onClick}
      className={configItem?.adminOnlyUnlocked ? "lock-unlocked" : "lock-locked"}
      css={css`
        &&& {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
          opacity: ${disabled ? "0.5" : "1"};
        }
      `}
      {...props}
    />
  );
}
