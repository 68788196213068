const partnersTranslations = {
  en: {
    partner: "Partner",
    notes: "Notes",
    accs: "Accs",
    fuelBilled: "Fuel Billed",
    hands: "Hands",
    win: "Win",
    rake: "Rake",
    insur: "Insur",
    errs: "Errs",
    fuelBalance: "Fuel Balance",
    fuelOperations: "Fuel Operations",
    history: "History",
    apply: "Apply",
    addPartner: "Add partner",
    addSlot: "Add slot",
    inOut: "Claim",
    topUp: "Top Up",
    myBalance: "My balance",
    partnerBalance: "Partner balance",
    fuelAmount: "FUEL AMOUNT",
    date: "Date",
    fuel: "Fuel",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS:
      "SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS",
    options: "Options",
    copyCode: "Copy Code",
    resetCode: "Reset Code",
    delete: "Delete",
    codeCopiedSuccessfully: "Code copied successfully",
    failedToCopyCode: "Failed to copy code",
    codeResetSuccessfully: "Code reset successfully",
    failedToResetCode: "Failed to reset code",
  },
  zh: {
    partner: "合伙人",
    notes: "备注",
    accs: "账号",
    fuelBilled: "燃料消耗",
    hands: "手数",
    win: "战绩",
    rake: "抽水",
    insur: "保险",
    errs: "误差",
    fuelBalance: "燃料余额",
    fuelOperations: "燃料操作",
    history: "操作记录",
    apply: "申请",
    addPartner: "添加合伙人",
    addSlot: "添加插槽",
    inOut: "提取",
    topUp: "添加",
    myBalance: "我的余额",
    partnerBalance: "合伙人余额",
    fuelAmount: "燃料量",
    date: "日期",
    fuel: "燃料",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS: "部分合伙人未删除账户",
    options: "选项",
    copyCode: "复制授权码",
    resetCode: "重置授权码",
    delete: "删除",
    codeCopiedSuccessfully: "授权码已复制成功",
    failedToCopyCode: "复制授权码失败",
    codeResetSuccessfully: "授权码重置成功",
    failedToResetCode: "授权码重置失败",
  },
  ja: {
    partner: "パートナー",
    notes: "メモ",
    accs: "アカウント",
    fuelBilled: "請求燃料",
    hands: "ハンド数",
    win: "ウィンレート",
    rake: "レーキ",
    insur: "インシュランス",
    errs: "エラー",
    fuelBalance: "燃料残高",
    fuelOperations: "燃料操作",
    history: "操作記録",
    apply: "適用",
    addPartner: "パートナーを追加する",
    addSlot: "スロットを追加する",
    inOut: "請求",
    topUp: "トップアップ",
    myBalance: "私の残高",
    partnerBalance: "パートナーの残高",
    fuelAmount: "燃料量",
    date: "日付",
    fuel: "燃料",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS:
      "アカウントを削除しなかったパートナーがいます",
    options: "オプション",
    copyCode: "コードをコピーする",
    resetCode: "コードをリセットする",
    delete: "削除",
    codeCopiedSuccessfully: "コードが正常にコピーされました",
    failedToCopyCode: "コードをコピーすることに失敗しました",
    codeResetSuccessfully: "コードが正常にリセットされました",
    failedToResetCode: "コードのリセットに失敗しました",
  },
};

export default partnersTranslations;
