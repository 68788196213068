const apiTranslations = {
  en: {
    SOMETHING_WENT_WRONG: "SOMETHING WENT WRONG",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS:
      "Some partners haven't deleted accounts",
    OPERATION_LOCKED: "Operation is locked",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_SUBPARTNER:
      "Partner is not allowed to create sub-partners",
    NOT_ALLOWED_PARTNER: "Partner is not allowed",
    NOT_ALLOWED_PARTNERS_OR_SOME_DOES_NOT_EXIST:
      "Some partners are not allowed or don't exist",
    PARTNER_DOES_NOT_EXIST: "Partner does not exist",
    INVALID_SRCPARTNER_DSTPARTNER: "Invalid source or destination partner",
    INVALID_FUEL_QUANTITY: "Invalid fuel amount",
    INSUFFICIENT_FUEL_BALANCE: "Insufficient fuel balance",
    INVALID_PARTNER: "Invalid partner",
    NOT_ALLOWED_ACCOUNTS: "Not allowed accounts",
    SOME_OF_ACCOUNTS_DO_NOT_EXIST: "Some accounts do not exist",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_RETAILERS:
      "Partner is not allowed to create retailers",
    RETAILER_IS_NOT_ALLOWED_TO_LOGIN_TO_ADMIN_PANEL:
      "Retailer is not allowed to log into the admin panel",
    TIME_FORMAT_ERROR: "Time format error",
    ERROR_OF_DELETING_TABLES: "Error deleting tables",
    CHECK_ROOMS_ALLOWED_FOR_PARTNER: "Check if rooms are allowed for the partner",
    DATE_START_INVALID: "Invalid start date",
    DATE_END_INVALID: "Invalid end date",
    MAPPING_FOR_PARTNER_AND_DEVICEID_WAS_NOT_FOUND:
      "Mapping for partner and device ID not found",
    API_CONVERT_ERROR: "API conversion error",
    NO_INVOICE_FOUND_FOR_TOKEN: "No invoice found for the token",
    API_LOGS_ERROR: "API logs error",
    INVOICE_IS_NOT_FOUND: "Invoice is not found",
    INVOICE_AND_LOGGED_PARTNERS_ARE_NOT_MATCHING:
      "Invoice and logged partners don't match",
    PLEASE_CHECK_AGAIN: "Please check again",
    MINIMUM_TRANSACTION_AMOUNT: "Minimum transaction amount",
    WALLETS_ARE_NOT_EQUAL_CONTACT_ADMIN: "Wallets are not equal, contact admin",
    NOT_ALLOWED_TO_DEPOSIT: "Not allowed to deposit",
    API_QRCODE_ERROR: "API QR code error",
    API_CREATE_ERROR: "API creation error",
    REACHED_DAILY_INVOICES_LIMIT: "Daily invoice limit reached",
    NO_SUCH_CURRENCY: "No such currency",
    INVALID_AMOUNT: "Invalid amount",
    PARTNER_AND_ACCOUNT_ARE_EMPTY: "Partner and account are empty",
    ACCOUNT_DOES_NOT_EXIST: "Account does not exist",
    INVALID_API_KEY: "Invalid API key",
    PARTNER_IS_NOT_SUBAGENT: "Partner is not sub-agent",
    UNKNOWN_COMMAND: "Unknown command",
    CONFID_IS_NOT_ALLOWED_BY_ADMIN: "Confid is not allowed by admin",
    ROOM_GAMETYPE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "Room game type not allowed for partner",
    CONFID_WAS_NOT_FOUND: "Confid was not found",
    REQUESTED_PARTNER_SHOULD_MATCH_PARTNER_OPENED_IN_THE_TOP:
      "Partner should match the one opened at the top",
    ROOM_GAMETYPE_MODE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "Room game type or mode not allowed for partner",
    LIMIT_ACCOUNT_REACHED: "Account limit reached",
    MULTILOGIN_DETECTED: "Multi-login detected",
    INVALID_PARTNER_AUTHKEY_OR_DISABLED: "Invalid partner auth key or disabled",
    INVALID_VALUES: "Invalid values",
    ACCOUNT_DISABLED: "Account disabled",
    CURRENT_PARTNER_IS_NOT_ADMIN: "Current partner is not admin",
    AUTH_REQUIRED: "Authentication required",
    PARTNER_IS_NOT_AGENT: "Partner is not agent",
    WS_ANDROIDID_MULTILOGIN_DETECTED: "This android ID already connected to device",
  },
  zh: {
    SOMETHING_WENT_WRONG: "出现错误",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS: "部分合伙人尚未删除账户",
    OPERATION_LOCKED: "操作已锁定",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_SUBPARTNER: "合伙人不允许创建子合伙人",
    NOT_ALLOWED_PARTNER: "不允许合伙人",
    NOT_ALLOWED_PARTNERS_OR_SOME_DOES_NOT_EXIST: "部分合伙人不被允许或不存在",
    PARTNER_DOES_NOT_EXIST: "合伙人不存在",
    INVALID_SRCPARTNER_DSTPARTNER: "无效的源合伙人或目标合伙人",
    INVALID_FUEL_QUANTITY: "无效燃油量",
    INSUFFICIENT_FUEL_BALANCE: "燃料余额不足",
    INVALID_PARTNER: "无效合伙人",
    NOT_ALLOWED_ACCOUNTS: "不允许的账户",
    SOME_OF_ACCOUNTS_DO_NOT_EXIST: "部分账户不存在",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_RETAILERS: "合伙人不允许创建零售用户",
    RETAILER_IS_NOT_ALLOWED_TO_LOGIN_TO_ADMIN_PANEL: "零售用户不允许登录管理面板",
    TIME_FORMAT_ERROR: "时间格式错误",
    ERROR_OF_DELETING_TABLES: "删除桌子时出错",
    CHECK_ROOMS_ALLOWED_FOR_PARTNER: "检查合伙人允许的平台",
    DATE_START_INVALID: "开始日期无效",
    DATE_END_INVALID: "结束日期无效",
    MAPPING_FOR_PARTNER_AND_DEVICEID_WAS_NOT_FOUND: "未找到合伙人和设备ID的映射",
    API_CONVERT_ERROR: "API转换错误",
    NO_INVOICE_FOUND_FOR_TOKEN: "未找到对应的发票",
    API_LOGS_ERROR: "API日志错误",
    INVOICE_IS_NOT_FOUND: "未找到发票",
    INVOICE_AND_LOGGED_PARTNERS_ARE_NOT_MATCHING: "发票和登录的合伙人不匹配",
    PLEASE_CHECK_AGAIN: "请再检查一次",
    MINIMUM_TRANSACTION_AMOUNT: "最低交易金额",
    WALLETS_ARE_NOT_EQUAL_CONTACT_ADMIN: "钱包不匹配，请联系管理员",
    NOT_ALLOWED_TO_DEPOSIT: "不允许存款",
    API_QRCODE_ERROR: "API二维码错误",
    API_CREATE_ERROR: "API创建错误",
    REACHED_DAILY_INVOICES_LIMIT: "已达到每日发票限制",
    NO_SUCH_CURRENCY: "不存在此种货币",
    INVALID_AMOUNT: "无效金额",
    PARTNER_AND_ACCOUNT_ARE_EMPTY: "合伙人和账户均为空的",
    ACCOUNT_DOES_NOT_EXIST: "账户不存在",
    INVALID_API_KEY: "无效的API密钥",
    PARTNER_IS_NOT_SUBAGENT: "合伙人不是子代理",
    UNKNOWN_COMMAND: "未知命令",
    CONFID_IS_NOT_ALLOWED_BY_ADMIN: "管理员不允许CONFID",
    ROOM_GAMETYPE_ARE_NOT_ALLOWED_FOR_THE_PARTNER: "不允许合伙人的平台游戏类型",
    CONFID_WAS_NOT_FOUND: "未找到CONFID",
    REQUESTED_PARTNER_SHOULD_MATCH_PARTNER_OPENED_IN_THE_TOP:
      "请求的合伙人应与顶部打开的合伙人匹配",
    ROOM_GAMETYPE_MODE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "不允许合伙人的平台游戏模式",
    LIMIT_ACCOUNT_REACHED: "已达到账户限制",
    MULTILOGIN_DETECTED: "检测到多重登录",
    INVALID_PARTNER_AUTHKEY_OR_DISABLED: "无效的合伙人授权密钥或已禁用",
    INVALID_VALUES: "无效值",
    ACCOUNT_DISABLED: "账户已禁用",
    CURRENT_PARTNER_IS_NOT_ADMIN: "当前合伙人不是管理员",
    AUTH_REQUIRED: "需要身份验证",
    PARTNER_IS_NOT_AGENT: "合伙人不是代理",
    WS_ANDROIDID_MULTILOGIN_DETECTED: "这个安卓ID已连接设备",
  },
  ja: {
    SOMETHING_WENT_WRONG: "エラーが生じました",
    SOME_OF_THE_PARTNERS_HAS_NOT_DELETED_ACCOUNTS:
      "アカウントを削除しなかったパートナーがいます",
    OPERATION_LOCKED: "操作がロックされています",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_SUBPARTNER:
      "パートナーがサブパートナーを作ることができません",
    NOT_ALLOWED_PARTNER: "パートナーが許されていません",
    NOT_ALLOWED_PARTNERS_OR_SOME_DOES_NOT_EXIST:
      "パートナーが許されていません。またはパートナーは存在していません",
    PARTNER_DOES_NOT_EXIST: "パートナーが存在しません",
    INVALID_SRCPARTNER_DSTPARTNER: "パートナーのソースか宛先が無効です",
    INVALID_FUEL_QUANTITY: "燃料量が無効です",
    INSUFFICIENT_FUEL_BALANCE: "燃料の残高不足です",
    INVALID_PARTNER: "パートナーが無効です",
    NOT_ALLOWED_ACCOUNTS: "アカウントが許されていません",
    SOME_OF_ACCOUNTS_DO_NOT_EXIST: "存在しないアカウントがあります",
    PARTNER_IS_NOT_ALLOWED_TO_CREATE_RETAILERS:
      "パートナーは小売業者を作ることができません",
    RETAILER_IS_NOT_ALLOWED_TO_LOGIN_TO_ADMIN_PANEL:
      "小売業者は管理パネルにログインできません",
    TIME_FORMAT_ERROR: "時間形式エラー",
    ERROR_OF_DELETING_TABLES: "テーブル削除エラー",
    CHECK_ROOMS_ALLOWED_FOR_PARTNER: "パートナーがルームに入れるかチェックする",
    DATE_START_INVALID: "開始日が無効です",
    DATE_END_INVALID: "終了日が無効です",
    MAPPING_FOR_PARTNER_AND_DEVICEID_WAS_NOT_FOUND:
      "パートナーのマッピングとデバイスIDが見つかりません",
    API_CONVERT_ERROR: "API変換エラー",
    NO_INVOICE_FOUND_FOR_TOKEN: "トークンの請求書が見つかりません",
    API_LOGS_ERROR: "APIログエラー",
    INVOICE_IS_NOT_FOUND: "請求書が見つかりません",
    INVOICE_AND_LOGGED_PARTNERS_ARE_NOT_MATCHING:
      "請求書とログインしたパートナーが一致しません",
    PLEASE_CHECK_AGAIN: "再度確認してください",
    MINIMUM_TRANSACTION_AMOUNT: "最小取引金額",
    WALLETS_ARE_NOT_EQUAL_CONTACT_ADMIN:
      "財布は等しくないので, 管理者に連絡してください",
    NOT_ALLOWED_TO_DEPOSIT: "入金はできません",
    API_QRCODE_ERROR: "APIのQRコードエラー",
    API_CREATE_ERROR: "API作成エラー",
    REACHED_DAILY_INVOICES_LIMIT: "毎日の請求書制限に達しました",
    NO_SUCH_CURRENCY: "そんな通貨がありません",
    INVALID_AMOUNT: "無効な金額",
    PARTNER_AND_ACCOUNT_ARE_EMPTY: "パートナーとアカウントが空です",
    ACCOUNT_DOES_NOT_EXIST: "アカウントが存在しません",
    INVALID_API_KEY: "APIキーが無効です",
    PARTNER_IS_NOT_SUBAGENT: "パートナーがサブエージェントではありません",
    UNKNOWN_COMMAND: "不明なコマンド",
    CONFID_IS_NOT_ALLOWED_BY_ADMIN: "管理者は構成IDを許可していません",
    ROOM_GAMETYPE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "パートナーはルームのゲームタイプを使用できません",
    CONFID_WAS_NOT_FOUND: "構成IDが見つかりません",
    REQUESTED_PARTNER_SHOULD_MATCH_PARTNER_OPENED_IN_THE_TOP:
      "パートナーがトップにあるパートナーに一致する必要です",
    ROOM_GAMETYPE_MODE_ARE_NOT_ALLOWED_FOR_THE_PARTNER:
      "パートナーはルームのゲームタイプかモードを使用できません",
    LIMIT_ACCOUNT_REACHED: "アカウント制限に達しました",
    MULTILOGIN_DETECTED: "二重ログインが検出されました",
    INVALID_PARTNER_AUTHKEY_OR_DISABLED: "パートナーの認証キーが無効か使用不可です",
    INVALID_VALUES: "値が無効です",
    ACCOUNT_DISABLED: "アカウントが無効になりました",
    CURRENT_PARTNER_IS_NOT_ADMIN: "現在パートナーは管理者ではありません",
    AUTH_REQUIRED: "認証が必要です",
    PARTNER_IS_NOT_AGENT: "パートナーはエージェントではありません",
    WS_ANDROIDID_MULTILOGIN_DETECTED:
      "この Android ID はすでにデバイスに接続されています",
  },
};

export default apiTranslations;
