const headerTranslations = {
  en: {
    accounts: "Accounts",
    sessions: "Sessions",
    partners: "Partners",
    fuelBalance: "Fuel Balance",
    accountConfiguration: "Configuration",
    deposit: "Deposit",
    usdtTrc20Amount: "USDT TRC20 amount",
    fuelAmount: "Fuel amount",
    add: "Add",
    nextGift: "for next gift",
    add100UsdtNextGift: "Add 100 usdt for next gift 5%",
    pay: "Pay",
    send: "Send",
    send10UsdtTrc20: "Send 10 USDT TRC20",
    congrats: "Congratulations 🎉",
    youHaveReachedTheMaximumGift: "You have reached the maximum gift",
    copyAddress: "Copy address",
    scanFromQr: "or Scan from qr code",
    iPaid: "I paid",
    confirmations: "confirmations",
    transactionReceived: "Transaction received",
    history: "History",
    topUpHistory: "Top-up history",
    transferHistory: "Transfer History",
    date: "Date",
    usdt: "Usdt",
    fuel: "Fuel",
    gift: "Gift",
    partner: "Partner",
    dark: "Dark",
    cn: "CN",
    apk: "APK",
    setTg: "Set Tg",
    runGuide: "Run guide",
    logOut: "Log out",
    search: "SEARCH...",
    restart: "Restart",
    delete: "Delete",
    agentCode: "Agent code",
    logIn: "Log In",
    hintsColour: "Hints Colour",
    text: "Text",
    background: "Background",
    textSize: "Size",
    accept: "Accept",
    settingsSaved: "Settings Saved",
    contactAgent: "Contact agent",
    contactSupport: "Contact support",
    deviceInfo: "Device Info",
    darkMode: "Dark mode",
    lightMode: "Light Mode",
    addFuel: "Add fuel",
    download: "Download",
    open: "Open",
    update: "Update",
    reinstall: "Reinstall",
    usual: "Usual",
    vip: "VIP",
    reset: "Reset",
    launch: "Launch",
    textExample: "Text example",
    PARTNER_KEY_CHANGED: "Partner key has changed",
    DELETE_PARTID_ACCID: "Account has been deleted",
    basic: "Basic",
    pro: "Pro",
    copied: "Copied to clipboard",
    depositSuccessful: "Deposit successful",
    chooseServer: "Choose server",
  },
  zh: {
    accounts: "账号",
    sessions: "游戏局",
    partners: "合伙人",
    fuelBalance: "燃料余额",
    accountConfiguration: "设置",
    deposit: "充值",
    usdtTrc20Amount: "USDT TRC20 金额",
    fuelAmount: "燃料量",
    add: "添加",
    nextGift: "下一份礼物赠送",
    add100UsdtNextGift: "添加100 USDT 获得下一份5%礼物",
    pay: "付款",
    send: "发送",
    send10UsdtTrc20: "发送 10 USDT TRC20",
    congrats: "恭喜🎉",
    youHaveReachedTheMaximumGift: "您的礼物已达到上限",
    copyAddress: "复制地址",
    scanFromQr: "或者扫二维码",
    iPaid: "我已经付款了",
    confirmations: "确认",
    transactionReceived: "转账已收到",
    history: "历史记录",
    topUpHistory: "充值记录",
    transferHistory: "转账记录",
    date: "日期",
    usdt: "USDT",
    fuel: "燃料",
    gift: "赠送",
    partner: "合伙人",
    dark: "暗色",
    cn: "英文",
    apk: "apk",
    setTg: "设置TG账户",
    runGuide: "运行指南",
    logOut: "退出",
    agentCode: "代理码",
    logIn: "登录",
    hintsColour: "提示颜色",
    text: "文本",
    background: "背景",
    textSize: "大小",
    accept: "接受",
    settingsSaved: "设置保存",
    contactAgent: "联系代理",
    contactSupport: "联系客服",
    deviceInfo: "设备信息",
    darkMode: "暗黑模式",
    lightMode: "明亮模式",
    addFuel: "添加燃料",
    download: "下载",
    open: "打开",
    update: "更新",
    reinstall: "重新安装",
    usual: "通常",
    vip: "VIP",
    reset: "重置",
    launch: "运行",
    textExample: "文字示例",
    basic: "Basic",
    pro: "Pro",
    copied: "已复制到剪贴板",
    search: "搜索",
    restart: "重新启动",
    delete: "删除",
    PARTNER_KEY_CHANGED: "合伙人密钥已更换",
    DELETE_PARTID_ACCID: "账号已被删除",
    depositSuccessful: "充值成功",
    chooseServer: "请选择服务器",
  },
  ja: {
    accounts: "アカウント",
    sessions: "セッション",
    partners: "パートナー",
    fuelBalance: "燃料バランス",
    accountConfiguration: "アカウント構成",
    deposit: "入金",
    usdtTrc20Amount: "USDT TRC20 金額",
    fuelAmount: "燃料量",
    add: "追加する",
    nextGift: "次のギフトへ",
    add100UsdtNextGift: "次のギフトに100 usdt（5%）を追加する",
    pay: "支払う",
    send: "贈る",
    send10UsdtTrc20: "10 USDT TRC20を１０贈る",
    congrats: "おめでとうございます 🎉",
    youHaveReachedTheMaximumGift: "グルメ限定に達しました",
    copyAddress: "アドレスをコピーしてください",
    scanFromQr: "またはQRコードを読み込んでください",
    iPaid: "順調に支払いました",
    confirmations: "確認",
    transactionReceived: "受信されたトランザクション",
    history: "履歴",
    topUpHistory: "トップアップ履歴",
    transferHistory: "転送履歴",
    date: "日付",
    usdt: "Usdt",
    fuel: "燃料",
    gift: "ギフト",
    partner: "パートナー",
    dark: "ダーク",
    cn: "CN",
    apk: "APK",
    setTg: "Telegramをセットする",
    runGuide: "ガイドを実行する",
    logOut: "ログアウト",
    search: "検索",
    restart: "再起動",
    delete: "削除",
    agentCode: "エージェントコード",
    logIn: "ログイン",
    hintsColour: "ヒントの色",
    text: "文字色",
    background: "背景",
    textSize: "文字サイズ",
    accept: "承諾する",
    settingsSaved: "設定が保存されました",
    contactAgent: "エージェントに連絡する",
    contactSupport: "サポートに連絡する",
    deviceInfo: "デバイス情報",
    darkMode: "ダークモード",
    lightMode: "ライトモード",
    addFuel: "燃料を補給する",
    download: "ダウンロード",
    open: "開く",
    update: "更新",
    reinstall: "再インストール",
    usual: "通常",
    vip: "VIP",
    reset: "リセット",
    launch: "起動",
    textExample: "文字列",
    PARTNER_KEY_CHANGED: "パートナーのキーが変更されました",
    DELETE_PARTID_ACCID: "アカウントが削除されました",
    basic: "基本",
    pro: "プロ",
    copied: "クリップボードにコピーしました",
    depositSuccessful: "入金成功",
    chooseServer: "サーバーを選択してください",
  },
};

export default headerTranslations;
