import { useAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";

import { useCurrentPartnerRooms } from "@/entities/partner/model/use-current-partner-rooms";

import { useConfigFiltersSelectOptions } from "../use-config-filters-select-options";
import { useConfigInitialFilters } from "../use-config-initial-filters";
import { configFiltersAtom } from "./config-filters-atom";
import { useConfigApplyFilters } from "./use-config-apply-filters";

export function useConfigFilters() {
  const allowedRooms = useCurrentPartnerRooms();
  const initialFilters = useConfigInitialFilters();

  const [filters, setFilters] = useAtom(configFiltersAtom);

  const { roomSelectOptions, roomTypeSelectOptions, botModeSelectOptions } =
    useConfigFiltersSelectOptions({
      selectedRoom: filters?.selectedRoom,
      selectedRoomType: filters?.selectedRoomType,
    });

  const isFetching = allowedRooms?.isFetching || initialFilters?.isFetching;

  useEffect(() => {
    if (isFetching) {
      setFilters({});
      return;
    }
    setFilters(initialFilters);
  }, [initialFilters, isFetching, setFilters]);

  const applyFilters = useConfigApplyFilters();

  const reset = useCallback(() => {
    setFilters(undefined);
  }, [setFilters]);

  return useMemo(
    () => ({
      isLoading: allowedRooms?.isLoading || initialFilters?.isLoading,
      isFetching: allowedRooms?.isFetching || initialFilters?.isFetching,
      setFilters,
      applyFilters,
      reset,
      filters,
      selectedRoom: filters?.selectedRoom,
      selectedRoomType: filters?.selectedRoomType,
      selectedBotMode: filters?.selectedBotMode,
      roomSelectOptions: roomSelectOptions || [],
      roomTypeSelectOptions: roomTypeSelectOptions || [],
      botModeSelectOptions: botModeSelectOptions || [],
    }),
    [
      allowedRooms?.isFetching,
      allowedRooms?.isLoading,
      botModeSelectOptions,
      filters,
      initialFilters?.isFetching,
      initialFilters?.isLoading,
      reset,
      roomSelectOptions,
      roomTypeSelectOptions,
      applyFilters,
      setFilters,
    ]
  );
}
