import "@fontsource/montserrat";
import { createTheme } from "@mui/material/styles";

import { MuiButton } from "@/app/theme/ui/button/mui-button";
import { MuiSelect } from "@/app/theme/ui/select/mui-select";
import { MuiTextField } from "@/app/theme/ui/text-field/mui-text-field";

// https://mui.com/material-ui/customization/css-theme-variables/configuration/#customizing-variable-prefix
export const theme = createTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          paper: "#F5F5F9",
        },
      },
    },
    dark: {
      palette: {
        background: {
          paper: "#232630",
        },
      },
    },
  },
  cssVariables: {
    colorSchemeSelector: "class",
  },
  typography: {
    fontFamily: "Montserrat",
  },
  components: {
    MuiButton,
    MuiSelect,
    MuiTextField,
  },
});
