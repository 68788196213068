import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, NavLinkProps } from "react-router-dom";

export function HeaderSessionsLink({ ...props }: Omit<NavLinkProps, "to">) {
  const { t } = useTranslation("header");
  return (
    <NavLink
      id="sessionsLink"
      to="/sessions"
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
      {...props}
    >
      {t("sessions")}
    </NavLink>
  );
}
