import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Components } from "@mui/material";
import { DefaultTheme } from "@mui/private-theming";

export const MuiSelect: Components<DefaultTheme>["MuiSelect"] = {
  defaultProps: {
    IconComponent: KeyboardArrowDownOutlinedIcon,
  },
  // styleOverrides: {
  //   root: ({ ownerState }) => {
  //     return {
  //       borderRadius: 0,
  //       textTransform: "none",
  //     };
  //   },
  // },
};
